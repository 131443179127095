/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addUser = /* GraphQL */ `
  mutation AddUser($input: UserInput!) {
    addUser(input: $input) {
      cognito_id
      email
      updated
      __typename
    }
  }
`;

export const putFeedback = /* GraphQL */ `
mutation PutFeedback($input: FeedbackInput!) {
  putFeedback(input: $input) {
    success
    message
  }
}
`;

export const updateUser = /* GraphQL */ `
  mutation updateUser($email: String!, $input: UpdateUserInput!) {
    updateUser(email: $email, input: $input) {
      gtag
    }
  }
`;