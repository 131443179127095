
import React from 'react';
import Plot from 'react-plotly.js';


export function MapComponent({mapCenter, zoomLevel,  updateMapView, mapboxStyle }) {

  const plotData = {
    type: 'scattermapbox',
    lat: [mapCenter.lat],
    lon: [mapCenter.lon],
    mode: 'markers',

    marker: {
      size: 14,       // Adjust size for visibility
      color: 'red',   // Red color for the cross
    },
    hoverinfo: 'none'
  };

  const plotLayout = {
      autosize: true,
      hovermode: 'closest',
      mapbox: {
          style: mapboxStyle || 'open-street-map',
          center: mapCenter,
          zoom: zoomLevel,
      },
      margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 0,
          pad: 4
      }
  };

  const plotStyle = {
      height: '100%',
  };

  const plotConfig = {
      displayModeBar: false
  };

 // Adjusted for clarity and correctness
  const handleRelayout = (eventData) => {
    if (eventData['mapbox.center'] && eventData['mapbox.zoom']) {
      const newCenter = {
        lat: eventData['mapbox.center'].lat,
        lon: eventData['mapbox.center'].lon,
      };
      const newZoom = eventData['mapbox.zoom'];

      // If mapbox._derived is available, use it to update bounds
      if (eventData['mapbox._derived']) {
        const coordinates = eventData['mapbox._derived'].coordinates;
        // Example logic to extract bounds, adjust according to eventData structure
        const newBounds = {
          max_lat: coordinates[0][1],
          min_lat: coordinates[2][1],
          max_lon: coordinates[1][0],
          min_lon: coordinates[0][0],
          
        };

        // Update mapView with both center, zoom, and bounds
        updateMapView({ ...newBounds, zoom: newZoom, center: newCenter });
      } else {
        // If no bounds, update only center and zoom
        updateMapView({ center: newCenter, zoom: newZoom });
      }
    }
  };



  return (
<div style={{ position: 'relative', touchAction: 'none', height: '80vh' }}>
          <Plot
            data={[plotData]}
            layout={plotLayout}
            style={plotStyle}
            config={plotConfig}
            onRelayout={handleRelayout}
          />
  </div>
  );
}




