import React, { useState } from 'react';
import { Nav, Navbar as BootstrapNavbar, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navigation = ({ onLinkClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const handleMapClick = (e) => {
    e.preventDefault(); // Prevent default only for the Maps button
    toggleExpand();
  };

  return (
    <Nav className="me-auto">
      <div className="expandable-container">
        <button 
          onClick={handleMapClick} 
          className={`expand-toggle custom-nav-link content-3 ${isExpanded ? 'expanded' : ''}`}
          aria-expanded={isExpanded}
        >
          Maps
        </button>
        {isExpanded && (
          <div className="expandable-content">
            <NavLink to="/map" className="nav-link" onClick={onLinkClick}>Prices Paid</NavLink>
            <NavLink to="/map/sqm" className="nav-link" onClick={onLinkClick}>Price per SQM</NavLink>
          </div>
        )}
      </div>
      <NavLink to="/trends" className="nav-link custom-nav-link content-3" onClick={onLinkClick}>Trends</NavLink>
      <NavLink to="/machine-learning-property-price-predictor" className="nav-link custom-nav-link content-3" onClick={onLinkClick}>Predictor</NavLink>
    </Nav>
  );
};

const Navbar = () => {
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = () => {
    setExpanded(false);
  };

  return (
    <BootstrapNavbar 
      expand="lg" 
      className="custom-navbar" 
      style={{ width: '100%', margin: 'auto' }}
      expanded={expanded}
      onToggle={(value) => setExpanded(value)}
    >
      <Container style={{ width: '80%' }}>
        <BootstrapNavbar.Brand href="/" className="custom-nav-link content-3" onClick={handleLinkClick}>
          <img
            src="otta_logo2.png"
            width="40"
            height="40"
            className="d-inline-block align-top"
            alt="Otta logo"
          />
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Navigation onLinkClick={handleLinkClick} />
          <Nav className="ms-auto align-items-center">
            <NavLink to="/about" className="nav-link custom-nav-link content-3 right-link" onClick={handleLinkClick}>About</NavLink>
            <NavLink to="/blog" className="nav-link custom-nav-link content-3 right-link" onClick={handleLinkClick}>Blog</NavLink>
            <NavLink to="/members" className="nav-link custom-nav-link content-3 members-link" onClick={handleLinkClick}>Account</NavLink>
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;