import { useState, useRef, useCallback } from 'react';
import pako from 'pako';

function generateUrls(lat, lon, sample_interval, radioValue, zoom) {
  const directions = [
      { dx: -1, dy: 1 },  // to the left and above
      { dx: 0, dy: 1 },   // above
      { dx: 1, dy: 1 },   // to the right and above
      { dx: 1, dy: 0 },   // to the right
      { dx: 1, dy: -1 },  // to the right and below
      { dx: 0, dy: -1 },  // below
      { dx: -1, dy: -1 }, // to the left and below
      { dx: -1, dy: 0 },
      { dx: 0, dy: 0},
      { dx: -2, dy: 0}, // to the left
      { dx: 2, dy: 0}, // to the right
  ];

  return directions.map(({ dx, dy }) => {
      const newLat = (parseFloat(lat) + sample_interval * dy).toFixed(5);
      const newLon = (parseFloat(lon) + sample_interval * dx).toFixed(5);
      return `panel/${radioValue}/${zoom}_${newLat}_${newLon}.json.gz`;
  });
}

export function useMapData() {
  const [mapData, setMapData] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: 51.5074, lon: -0.1278 });
  const [zoomLevel, setZoomLevel] = useState(14);
  const [colorBarRange, setColorBarRange] = useState([0, 1000000]);
  const isFetchingRef = useRef(false);

  const fetchMapData = useCallback(async (lon, lat, zoom, radioValue) => {
    if (isFetchingRef.current) return;
    isFetchingRef.current = true;
    // Defines the sampling interval based on zoom levels
    const zoomLevelToSample = {
      6: { 'sample_interval': 6},
      7: { 'sample_interval': 3},
      8: { 'sample_interval': 1.5},
      9: { 'sample_interval': 0.75},
      10: { 'sample_interval': 0.375},
      11: { 'sample_interval': 0.1875},
      12: { 'sample_interval': 0.09375},
      13: { 'sample_interval': 0.04}
    };

    zoom = Math.max(6, Math.min(13, Math.floor(zoom))); // Ensures zoom is within the range [6, 13]

    const sample_interval = zoomLevelToSample[zoom]['sample_interval'];
    
    // Rounds lat and lon to the nearest sample interval and formats to five decimal places
    lat = (Math.floor(lat / sample_interval) * sample_interval).toFixed(5);
    lon = (Math.floor(lon / sample_interval) * sample_interval).toFixed(5);

    try {
      // Generate URLs based on input parameters, including the new panelType
      const urls = generateUrls(lat, lon, sample_interval, radioValue, zoom);
      // Fetch the gzipped JSON files

      const responses = await Promise.all(urls.map(url => fetch(url)));
    
      // Convert each response to an ArrayBuffer, then decompress and parse the JSON
      const jsons = await Promise.all(responses.map(async (response) => {
        if (!response.ok) {
          return null; // or handle HTTP errors
        }
    
        try {
          const buffer = await response.arrayBuffer(); // Get the response as ArrayBuffer
          const decompressed = pako.inflate(new Uint8Array(buffer), { to: 'string' }); // Decompress
    
          // Check if decompressed content is empty or null before parsing
          if (!decompressed || decompressed.trim() === '') {
            return null;
          }
    
          return JSON.parse(decompressed); // Parse the decompressed string into JSON
        } catch (error) {
          return null;
        }
      }));
    
      // Filter out null values (failed JSON parses) and then concatenate the rest

      const data = jsons.filter(json => json !== null).reduce((acc, json) => {
        // Check if json is an array or an object and concatenate accordingly
        if (Array.isArray(json)) {
          return acc.concat(json);
        } else if (json && typeof json === 'object') {
          return acc.concat([json]); // Wrap the object in an array before concatenating
        } else {
          return acc; // If json is neither an array nor an object, just return the accumulator
        }
      }, []);
      
      if (Array.isArray(data)) {
        setMapData(data);
        const sortedPrices = data.map(d => d.price).sort((a, b) => a - b);
        setColorBarRange([
          sortedPrices[Math.floor(sortedPrices.length * 0.05)], // 5th percentile
          sortedPrices[Math.floor(sortedPrices.length * 0.95)]  // 95th percentile
        ]);
      } else {
        console.error('Expected data to be an array, but got:', typeof data);
        setMapData([]);
      }
    } catch (error) {
      console.error('Failed to fetch or process data:', error);
      setMapData([]);
    } finally {
      isFetchingRef.current = false;
    }
  }, []);

  return {
    mapData,
    mapCenter,
    zoomLevel,
    colorBarRange,
    setMapCenter,
    fetchMapData,
    setZoomLevel
  };
}