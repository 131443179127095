import Home from './pages/Home';
import Trends from './pages/Trends/Trends';
import About from './pages/About';
import Blog from './pages/Blog';
import {MemberApp, PredictorApp} from './ServiceApps'; // Ensure this path is correct
import Map from './pages/PPMap/Map';
import SqmMap from './pages/SqmMap/SqmMap';
import BlogPost from './pages/BlogPost';

import { Navigate } from 'react-router-dom';

import Privacy from './pages/Privacy';
import TermsAndConditions from './pages/TermsAndConditions ';
// Define routes
const routes = [
  { path: "/", element: <Home /> },
  { path: "/home", element: <Navigate to="/" replace /> },  // Redirect /home to /
  { path: "/trends", element: <Trends /> },
  { path: "/map", element: <Map /> },
  { path: "/map/sqm", element: <SqmMap /> },
  { path: "/about", element: <About /> },
  { path: "/members", element: <MemberApp /> },
  { path: "/machine-learning-property-price-predictor", element: <PredictorApp /> },
  { path: "/privacy", element: <Privacy /> },
  { path: "/terms", element: <TermsAndConditions /> },
  {path: "/blog", element: <Blog />},
  {path: "/:postId", element: <BlogPost />},
  { path: "*", element: <p>Page not found</p> }
];

export default routes;
