import React, { useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import SiteCard from '../../components/SiteCard';
import SEOComponent from '../../components/SEOComponent';




const May2024 = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const imagesAll = require.context('../../../public/blog_images/All', false, /\.(png|jpe?g|svg)$/);
    const imagesDetached = require.context('../../../public/blog_images/Detached', false, /\.(png|jpe?g|svg)$/);
    const imagesSemiDetached = require.context('../../../public/blog_images/Semi-detached', false, /\.(png|jpe?g|svg)$/);
    const imagesTerraced = require.context('../../../public/blog_images/Terraced', false, /\.(png|jpe?g|svg)$/);
    const imagesFlat = require.context('../../../public/blog_images/Flat', false, /\.(png|jpe?g|svg)$/);

    const handleAllImageClick = (file) => {
        setSelectedImage(imagesAll(file));
        setShowModal(true);
    };

    const handleDetachedImageClick = (file) => {
        setSelectedImage(imagesDetached(file));
        setShowModal(true);
    };

    const handleSemiDetachedImageClick = (file) => {
        setSelectedImage(imagesSemiDetached(file));
        setShowModal(true);
    };

    const handleTerracedImageClick = (file) => {
        setSelectedImage(imagesTerraced(file));
        setShowModal(true);
    };

    const handleFlatImageClick = (file) => {
        setSelectedImage(imagesFlat(file));
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage(null);
    };

    return (
        <>
            <SEOComponent 
                title={'Latest Prices: May 2024'}
                description={'A quick analysis of the latest prices from the land registry data'}
                url={'https://otta.property/blog'}
                image={imagesAll(imagesAll.keys()[0])}
            />
            <Container>
                <SiteCard 
                    header={'Latest Prices: May 2024'} 
                    title={'A quick analysis of May 2024 transactions'} 
                    content={
                        <Container>
                            <Row>
                                <Col md={10} style={{margin: 'auto'}}>
                                    <p>
                                        Here's a quick set of charts from the latest data from the land registry.
                                        The data is from the land registry 'Prices Paid' dataset. There's no hedonic regression here (or seasonal adjustments), just the raw data. I'm open to feedback
                                        as always. The data is for England and Wales only.
                                    </p> 
                                    <Container>
                                        <h3>Some notes</h3>
                                        <Row>
                                            <h5>The average price chart</h5>
                                            <ul style={{paddingLeft: '50px'}}>
                                                <li>The monthly scatter points represent the geometric mean of the prices paid in that month.</li>
                                                <li>The smoothed line is the rolling 12-month average of the geometric mean, which has been centred.</li>
                                            </ul>
                                        </Row>
                                        <Row>
                                            <h5>12 month change chart</h5>
                                            <ul style={{paddingLeft: '50px'}}>
                                                <li>Using the rolling 12-month average of the geometric mean (calculated in the previous section), the percentage change is calculated for each month by 
                                                    comparing it to the same month in the previous year.</li>
                                                <li>Using the smoothed line is a subjective choice, but it helps to show the signal from the noise.</li>
                                            </ul>
                                        </Row>
                                        <Row>
                                            <h5>Bar chart for regional change</h5>
                                            <ul style={{paddingLeft: '50px'}}>
                                                <li>The chart shows the best and worst performing regions in England and Wales.</li>
                                                <li>The difference is calculated as the percentage change in the rolling 12-month average of the geometric mean.</li>
                                                <li>If a region has fewer than 5 transactions for that property type in the month, it is excluded from the chart.</li>
                                            </ul>
                                        </Row>

                                    </Container>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10} style={{margin: 'auto'}}>
                                    <h2>Dwelling Type: All</h2>
                                    <hr/>
                                </Col>
                            </Row>
                            {imagesAll.keys().map((file, index) => (
                                <Row key={file} style={{textAlign: 'center', marginBottom: '20px'}}>
                                    <Col>
                                        <img 
                                            src={imagesAll(file)} 
                                            alt={`House price visualization ${index + 1}`} 
                                            className="img-fluid"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleAllImageClick(file)}
                                        />
                                    </Col>
                                </Row>
                            ))}
                            <Row>
                                <Col md={10} style={{margin: 'auto'}}>
                                    <h2>Dwelling Type: Detached</h2>
                                    <hr/>
                                </Col>
                            </Row>
                            {imagesDetached.keys().map((file, index) => (
                                <Row key={file} style={{textAlign: 'center', marginBottom: '20px'}}>
                                    <Col>
                                        <img 
                                            src={imagesDetached(file)} 
                                            alt={`House price visualization ${index + 1}`} 
                                            className="img-fluid"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleDetachedImageClick(file)}
                                        />
                                    </Col>
                                </Row>
                            ))}
                            <Row>
                                <Col md={10} style={{margin: 'auto'}}>
                                    <h2>Dwelling Type: Semi-detached</h2>
                                    <hr/>
                                </Col>
                            </Row>
                            {imagesSemiDetached.keys().map((file, index) => (
                                <Row key={file} style={{textAlign: 'center', marginBottom: '20px'}}>
                                    <Col>
                                        <img 
                                            src={imagesSemiDetached(file)} 
                                            alt={`House price visualization ${index + 1}`} 
                                            className="img-fluid"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleSemiDetachedImageClick(file)}
                                        />
                                    </Col>
                                </Row>
                            ))}
                            <Row>
                                <Col md={10} style={{margin: 'auto'}}>
                                    <h2>Dwelling Type: Terraced</h2>
                                    <hr/>
                                </Col>
                            </Row>
                            {imagesTerraced.keys().map((file, index) => (
                                <Row key={file} style={{textAlign: 'center', marginBottom: '20px'}}>
                                    <Col>
                                        <img 
                                            src={imagesTerraced(file)} 
                                            alt={`House price visualization ${index + 1}`} 
                                            className="img-fluid"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleTerracedImageClick(file)}
                                        />
                                    </Col>
                                </Row>
                            ))}
                            <Row>
                                <Col md={10} style={{margin: 'auto'}}>
                                    <h2>Dwelling Type: Flat</h2>
                                    <hr/>
                                </Col>
                            </Row>
                            {imagesFlat.keys().map((file, index) => (
                                <Row key={file} style={{textAlign: 'center', marginBottom: '20px'}}>
                                    <Col>
                                        <img 
                                            src={imagesFlat(file)} 
                                            alt={`House price visualization ${index + 1}`} 
                                            className="img-fluid"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleFlatImageClick(file)}
                                        />
                                    </Col>
                                </Row>
                            ))}
                        </Container>
                    }
                />
            </Container>

            <Modal show={showModal} onHide={handleCloseModal} centered size="lg" style={{padding: '0', margin: '0', width: '100%'}}>
                <Modal.Header closeButton>
                    <Modal.Title>double tap to zoom further in</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedImage && (
                        <TransformWrapper
                            initialScale={1}
                            initialPositionX={0}
                            initialPositionY={0}
                        >
                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                <React.Fragment>
                                    <TransformComponent>
                                        <img src={selectedImage} alt="House price visualization" style={{width: '100%'}} />
                                    </TransformComponent>
                                </React.Fragment>
                            )}
                        </TransformWrapper>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};


export default May2024;