import React from 'react';
import { Container, Row } from 'react-bootstrap';
import PropertyTypeSelect from './PropertyTypeSelect';  
import PropertyAreaSelect from './PropertyAreaSelect';  
import {fetchChartData} from './fetchChartData';
import { useEffect, useState } from 'react';


const RawDataTab = ({ selectedCounty, rawPropertyType, onPropertyTypeChange, onRawLocationValuesChange }) => {
  const [charts1, setCharts1] = useState(null);
  const [charts2, setCharts2] = useState(null);
  const [PropertyArea, setPropertyArea] = useState('All');
  const [RawLocationValues, setRawLocationValues] = useState({});
  const [postCodeLongNames, setPostCodeLongNames] = useState({});
  const [longName, setLongName] = useState('');

  const handlePropertyTypeChange = (newType) => {
      if (onPropertyTypeChange) {
          onPropertyTypeChange(newType); // Call the parent component's callback
      }
  };


  const handlePropertyAreaChange = (newArea) => {
    setPropertyArea(newArea);
  }




  useEffect(() => {
    const basePath = window.location.hostname === 'localhost' ? 'data' : 'trend';
    if (selectedCounty) {
      fetchChartData([`${basePath}/${selectedCounty}/${rawPropertyType}.json`])
        .then(setCharts1);
    }
  }, [selectedCounty, rawPropertyType]);


  useEffect(() => {
    const basePath = window.location.hostname === 'localhost' ? 'data' : 'trend';
    if (selectedCounty) {
      fetchChartData([`${basePath}/${selectedCounty}/${rawPropertyType}_${PropertyArea}.json`])
        .then(setCharts2);
    }
  }, [selectedCounty, rawPropertyType, PropertyArea]);

  useEffect(() => {
    const basePath = window.location.hostname === 'localhost' ? 'data' : 'misc';
    fetch(`${basePath}/uk_postcode_longnames.json`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok');
      }
      )
      .then(setPostCodeLongNames)
      .catch(error => console.error('Fetch error:', error));
  }, []);
  
  // New useEffect hook for setting longName based on selectedCounty
  useEffect(() => {
    if (selectedCounty && postCodeLongNames[selectedCounty]) {
      setLongName(postCodeLongNames[selectedCounty]);
    }
  }, [selectedCounty, postCodeLongNames]);

  useEffect(() => {
    const basePath = window.location.hostname === 'localhost' ? 'data' : 'trend';
    fetch(`${basePath}/region${rawPropertyType}.json`)
        .then(response => response.ok ? response.json() : Promise.reject('Network response was not ok'))
        .then(data => {
            setRawLocationValues(data); // Set local state
            if (onRawLocationValuesChange) {
                onRawLocationValuesChange(data); // Call the parent callback
            }
        })
        .catch(error => console.error('Fetch error:', error));
}, [rawPropertyType, onRawLocationValuesChange]);

  const renderTitle = () => {
    const heading = rawPropertyType === 'All' ? 'All property types' : rawPropertyType;
    return (
      <h4>{longName}, {heading}</h4>

    );
  };

  const renderSubtitle = () => {
    const heading = rawPropertyType === 'All' ? 'All property types' : rawPropertyType;
    return (
      <p>{longName}, {heading}</p>
    );
  }

  const renderCommentary = () => {
    const data = RawLocationValues[selectedCounty];
    if (data) {
      const { max_value, twelve_months_ago, max_date, last_date, last_value, percent_change, last_and_max_percent_change } = data;
      return (
        <div>
          <p style={{ textAlign: 'left', fontSize: '0.8em' }}>
            Most recent prices are £{Math.round(last_value / 1000)}k in {last_date}. <br />
            Prices 12 months ago were £{Math.round(twelve_months_ago / 1000)}k. This is a {percent_change.toFixed(1)}% change.<br />
            Prices peaked at £{Math.round(max_value / 1000)}k in {max_date}. The change from peak to now is {last_and_max_percent_change.toFixed(1)}%.
          </p>
        </div>
      );
    }
    return null;
  };


  return (

      <Container className='chart-container' style={{marginTop: '1rem'}}> 
        {renderTitle()}
        {renderCommentary()} 
        <Row>
          <PropertyTypeSelect
            propertyTypes={['All', 'Flat', 'Terraced', 'Semi-detached', 'Detached']}
            onSelect={handlePropertyTypeChange}
          />
          <Container style={{display: 'flex'}}>
            {charts1 || <div><b>Click on the map and buttons to load a chart</b></div>}
          </Container>
        </Row>
        <Row style={{marginTop: '1rem'}}>
          <Container>
            <h4>Price per square metre</h4>
            <p>{renderSubtitle()}</p>
          </Container>
        </Row>
        <Row>
          <PropertyAreaSelect
            propertyArea={['All', '40-65', '65-85', '85-110', '110-160', '160-230', '230']}
            onSelect={handlePropertyAreaChange}
          />
          <Container style={{display: 'flex'}}>
            {charts2 || <div><b>Click on the map and buttons to load a chart</b></div>}
          </Container>
        </Row>
        <Row style={{marginTop: '.5rem', fontSize: '0.8rem'}}>
          <Container>
            <p>Data displayed is the Geometric Mean of the 'Prices Paid' dataset sourced from the Land Registry.
              Please bear in mind that the data is incomplete for the most recent months and may also have a signficant time lag. 
              Transactions are often completed many months after the sale is agreed, so the numbers reflect the market conditions from previous months.
            </p>
            <p>
              The floor area data comes from EPC certificates and is not available for all properties. The data is also subject to errors and omissions. There are plenty
              of examples where EPCs have been completed and the unit for area has been done in square feet rather than square metres. Treat with a signficant pile of salt.
            </p>
          </Container>
        </Row>
      </Container>

  );
};

export default RawDataTab;
