import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import usePageTracking from './usePageTracking';
import routes from './routes';
import Navbar from "./components/Navbar";
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <PageTrackingWrapper />
    </Router>
  );
}

const PageTrackingWrapper = () => {
  usePageTracking();

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      
      <main className="flex-1 w-full pb-32"> {/* Increased padding to account for footer height */}
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </main>

      {/* Fixed footer */}
      <div className="fixed bottom-0 left-0 right-0 bg-white">
        <Footer />
        {/* Extra white space below footer */}
        <div className="h-32 bg-white" />
      </div>
    </div>
  );
}

export default App;