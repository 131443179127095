import { addUser, updateUser } from '../graphql/mutations';
import { getUser } from '../graphql/queries';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { generateClient } from '@aws-amplify/api';

const client = generateClient();

// Cache configuration
const CACHE_TTL = 15 * 1000; // 15 seconds

// Simplified cache key generation
function generateCacheKey(email) {
    return `user-${email}`;
}

// Improved caching function with TTL
function cacheUserData(cacheKey, userData) {
    const item = {
        data: userData,
        expiry: Date.now() + CACHE_TTL
    };
    localStorage.setItem(cacheKey, JSON.stringify(item));
}

// Improved cache retrieval with TTL check
function getCachedUser(cacheKey) {
    const item = localStorage.getItem(cacheKey);
    if (!item) return null;

    const { data, expiry } = JSON.parse(item);
    if (Date.now() > expiry) {
        localStorage.removeItem(cacheKey);
        return null;
    }

    return data;
}

// Function to invalidate cache
function invalidateCache(email) {
    const cacheKey = generateCacheKey(email);
    localStorage.removeItem(cacheKey);
}

async function fetchUserData(email) {
    try {
        const response = await client.graphql(graphqlOperation(getUser, { email }));
        return response.data.getUser;
    } catch (error) {
        console.error("Error fetching user:", error);
        return null;
    }
}

async function updateUserFunc(email, input) {
    try {
        const response = await client.graphql(graphqlOperation(updateUser, { email, input }));
        invalidateCache(email); // Invalidate cache after update
        return response;
    } catch (error) {
        console.error("Error updating user:", error);
        throw error;
    }
}

async function addUserFunc(item) {
    try {
        const response = await client.graphql(graphqlOperation(addUser, { input: item }));
        return response;
    } catch (error) {
        console.error("Error adding user:", error);
    }
}

async function getUserFunc(email) {
    const cacheKey = generateCacheKey(email);
    const cachedUser = getCachedUser(cacheKey);
    if (cachedUser) {
        return cachedUser;
    }

    const fetchedUser = await fetchUserData(email);
    if (fetchedUser) {
        cacheUserData(cacheKey, fetchedUser);
        return fetchedUser;
    }

    return null;
}

async function fetchOrUpdateUserStatus(user) {
    const fetchedUser = await getUserFunc(user.signInDetails.loginId);
    if (fetchedUser) {
        fetchedUser.stripe = fetchedUser.stripe || null;
        
        if (!fetchedUser.gtag) {
            runConversionScript();
            
            const input = { gtag: true };
            await updateUserFunc(fetchedUser.email, input);
        }
        
        return fetchedUser;
    } else {
        try {
            const item = {
                cognito_id: user.userId,
                email: user.signInDetails.loginId,
                updated: new Date().toISOString(),
            };
            await addUserFunc(item);
            return item;
        } catch (error) {
            console.error("Failed to add user:", error);
            return null;
        }
    }
}

function runConversionScript() {
    if (window.gtag) {
        /* global gtag */
        gtag('event', 'conversion', { 'send_to': 'AW-16522909255/ZkbbCIGNmLsZEMes3sY9', 'value': 1.99, 'currency': 'GBP' });
        // update the user's gtag status

    } else {
        }
}


export default fetchOrUpdateUserStatus;