import React from 'react';


import './radioButtons.css';

export function RadioButtons ({ radioValue, onChange }) {
    return (
        <div className="radio-buttons">
            <label>
                <input
                    type="radio"
                    value="Flat"
                    checked={radioValue === "Flat"}
                    onChange={onChange}
                />
                Flats
            </label>
            <label>
                <input
                    type="radio"
                    value="Terraced"
                    checked={radioValue === "Terraced"}
                    onChange={onChange}
                />
                Terraced
            </label>
            <label>
                <input
                    type="radio"
                    value="Semi-detached"
                    checked={radioValue === "Semi-detached"}
                    onChange={onChange}
                />
                Semi-Detached
            </label>
            <label>
                <input
                    type="radio"
                    value="Detached"
                    checked={radioValue === "Detached"}
                    onChange={onChange}
                />
                Detached
            </label>
        </div>
    );
}




export function TypeDropdown({ radioValue, onChange }) {
    return (
        <div className="dropdown" id='dropdown' >
            <label className="dropdown-label" id="dropdown-label" htmlFor="dropdown-select">Property Type:</label>
            <select value={radioValue} onChange={onChange} className="dropdown-select" id='dropdown-select'>
                <option value="All">All</option>
                <option value="Flat">Flat</option>
                <option value="Terraced">Terraced</option>
                <option value="Semi-detached">Semi-Detached</option>
                <option value="Detached">Detached</option>
            </select>
        </div>
    );
}

export function PanelRadioButtons({ radioValue, onChange }) {
    return (
        <div className="panel-radio-buttons">
            <label>
                <input
                    type="radio"
                    value="panel"
                    checked={radioValue === "panel"}
                    onChange={onChange}
                />
               Price
            </label>
            <label>
                <input
                    type="radio"
                    value="sqm_panel"
                    checked={radioValue === "sqm_panel"}
                    onChange={onChange}
                />
                Price per sqm
            </label>
        </div>
    );
}