/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = `
  query GetUser($email: ID!) {
    getUser(email: $email) {
      cognito_id
      email
      updated
      stripe {
        current_period_end
        current_period_start
        cancel_at_period_end
        canceled_at
        stripe_sub_status
      }
      gtag
      __typename
    }
  }
`;

export const getPrediction = `
  query GetPrediction($input: PredictionInput!) {
    getPrediction(input: $input) {
      message
      predictions {
        value
      }
      __typename
    }
  }
`;
