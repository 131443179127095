import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

export const AdComponent = () => {
  useEffect(() => {
    const loadAdSenseScript = () => {
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1503615178210716';
      script.async = true;
      script.crossOrigin = 'anonymous';
      document.head.appendChild(script);
    };

    const initializeAd = () => {
      try {
        if (window.adsbygoogle) {
          window.adsbygoogle.push({});
        }
      } catch (error) {
        console.error('AdSense error:', error);
      }
    };

    if (!document.querySelector('script[src*="pagead2.googlesyndication.com"]')) {
      loadAdSenseScript();
    }

    initializeAd();
  }, []);

  return (
    <Container className="p-0 text-center">
      <div className="mx-auto w-full">
        <ins
          className="adsbygoogle"
          style={{
            display: 'block',
            height: '100%',
            width: '100%',
            maxHeight: '280px',
            margin: '0 auto'
          }}
          data-ad-client="ca-pub-1503615178210716"
          data-ad-slot="9164483736"
          data-ad-format="auto"
          data-full-width-responsive="true"
        />
      </div>
    </Container>
  );
};

export const SmallAdComponent = () => {
  useEffect(() => {
    const initializeAd = () => {
      try {
        if (window.adsbygoogle) {
          window.adsbygoogle.push({});
        }
      } catch (error) {
        console.error('AdSense error:', error);
      }
    };

    initializeAd();
  }, []);

  return (
    <Container className="p-0 text-center">
      <div className="mx-auto w-full">
      <ins class="adsbygoogle"
      style= {{ display: 'block' , width: 728, height: 90, margin: '0 auto'}}
     data-ad-client="ca-pub-1503615178210716"
     data-ad-slot="5692647162"></ins>
      </div>
    </Container>
  );
};

