export function calculateModeRange(values, binSize = 25000) {

    const bins = {};

    values.forEach(value => {
        const binIndex = Math.floor(value / binSize) * binSize;
        bins[binIndex] = (bins[binIndex] || 0) + 1;
    });

    let maxCount = 0;
    let modeBins = [];

    // Find the maximum count and all bins with that count
    for (const [bin, count] of Object.entries(bins)) {
        if (count > maxCount) {
            maxCount = count;
            modeBins = [parseInt(bin)];
        } else if (count === maxCount) {
            modeBins.push(parseInt(bin));
        }
    }

    // Sort the mode bins and select the middle one
    modeBins.sort((a, b) => a - b);
    const modeValue = modeBins[Math.floor(modeBins.length / 2)];

    return `£${modeValue.toLocaleString()} - £${(modeValue + binSize).toLocaleString()}`;
}

export function calculateMean(values) {
    return Math.round(values.reduce((sum, value) => sum + value, 0) / values.length);
}

export function calculateMedian(values) {
    const sortedValues = values.slice().sort((a, b) => a - b);
    const middle = Math.floor(sortedValues.length / 2);

    if (sortedValues.length % 2 === 0) {
        return Math.round((sortedValues[middle - 1] + sortedValues[middle]) / 2);
    }

    return sortedValues[middle];
}