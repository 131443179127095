import React from 'react';
import { Col, Container , Row} from 'react-bootstrap';
import { Authenticator } from '@aws-amplify/ui-react';
import SiteCard from './SiteCard';


export const CallToJoin = () => {
  return (
    <Container style={{ width: '100%' }}>
<SiteCard
    header="This page is for subscribers only"
    title="Sample for free - no card details required"
    content={
      <Container>
        <Row className="justify-content-center align-items-center" style={{ minHeight: '100%' }}>
          <Col md={6} className="text-center">
            <h5>Unlock advanced features</h5>
            <p>Sign up in just a minute and start discovering in-depth trends and insights to empower your property decisions.</p>
            <p>This site is a project created by just one person, and your support makes a big difference.</p>
            <p>You can join for £3.99 per month, but you can cancel anytime!</p>
            </Col>
            </Row>
            <Container>
              <Authenticator />
            </Container>
            <br />

            <Row className="justify-content-center align-items-center" style={{ minHeight: '100%' }}>

            <Col md={6} className="text-center">
            <p>Thank you for considering joining!</p>
            </Col>
            </Row>

      </Container>
    }
/>

    </Container>
  );
};


export const CallToJoinTrends = () => {
  return (
    <Container style={{ width: '100%' }}>
<SiteCard
    header="This page is for subscribers only"
    content={

            <Container fluid className="h-100">
              <Row className="h-100 align-items-center" style={{ minHeight: '40vh' }}>
                  <Col md={6} className="d-flex align-items-center justify-content-center">
                      <img src="/trend.webp" alt="Property Trends Preview" className="img-fluid rounded shadow site-card-image" />
                  </Col>
                  <Col md={6} className="d-flex flex-column justify-content-center">

                      <h3 className="text-center" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                          The First Ever ML based Property Index
                      </h3>
                      <p>Stay ahead of the market with our comprehensive trend analysis. Get insights into price movements, market hotspots, and emerging patterns in the property landscape.
                          Made possible by our cutting-edge machine learning algorithms, our trend analysis tool is the first of its kind in the market.</p>
                      <div className="text-center" style={{ marginTop: '1rem' , marginBottom: '1rem'}}>
                      </div>
                  </Col>
              </Row>
              <Container>
              <Authenticator />
            </Container>
            </Container>

    }
/>

    </Container>
  );
};

export const CallToJoinPredictor = () => {
  return (
    <Container style={{ width: '100%' }}>
<SiteCard
    header="This page is for subscribers only"
    content={

            <Container fluid className="h-100">

                                <Row className="h-100 align-items-center" style={{ minHeight: '40vh' }}>
                                <Col md={6} className="d-flex align-items-center justify-content-center">
                                        <img src="/predictor.webp" alt="First-Ever ML Prediction Tool" className="img-fluid rounded shadow site-card-image" />
                                    </Col>
                                    <Col md={6} className="d-flex flex-column justify-content-center">
                                        <h3 className="text-center" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                            Free Machine Learning Property Price Predictor
                                        </h3>
                                        <p>
                                            <strong>Groundbreaking Technology:</strong> Introducing the market's first-ever machine learning tool for property prediction.
                                        </p>
                                        <p>
                                            Harness the power of advanced algorithms to:
                                        </p>
                                        <ul>
                                            <li>Predict property values with unprecedented accuracy</li>
                                            <li>Make data-driven decisions</li>
                                        </ul>
                                        <p>
                                            Stay ahead of the curve with this revolutionary tool, exclusively available here at otta.property.
                                        </p>
           
                                    </Col>
 
                                </Row>
              <Container>
              <Authenticator />
            </Container>
            </Container>

    }
/>

    </Container>
  );
};

export const CalltoMakeAccount = () => {
  return (
    <Container style={{ width: '100%' }}>
<SiteCard
    header="Free ML Enabled Property Price Predictor"
    title="Scope out your local property market using advanced machine learning"
    content={
      <Container>
        <Row className="justify-content-center align-items-center" style={{ minHeight: '100%' }}>
          <Col md={6} className="text-center">
            <h5>Make predictions for free!</h5>
            <p>Sign up in just a minute and to use the latest machine learning house price tool.</p>
            </Col>
            </Row>
            <Container>
              <Authenticator />
            </Container>
            <br />


      </Container>
    }
/>

    </Container>
  );
};
