import React from 'react';


export const BuyButton = ({ userEmail }) => {
  return (
    <stripe-buy-button
      buy-button-id={process.env.REACT_APP_STRIPE_BUY_BUTTON_ID}
      publishable-key={process.env.REACT_APP_STRIPE_PK}
      customer-email={userEmail}      
    >
    </stripe-buy-button>
  );
}