// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Correct import for React 18
import './index.css';
import './styles.css'; // Import your custom CSS here
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure this is loaded after index.css to maintain CSS precedence
import '@aws-amplify/ui-react/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


// Configuration Imports
import { configureAmplify, initializeAnalytics } from './config/appConfig';

// Initialize configuration settings
configureAmplify();
initializeAnalytics();

// Create a root container where the React application will attach
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the application within React's StrictMode for highlighting potential problems
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Performance monitoring, pass a function to log results, for example, reportWebVitals(console.log)
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
