// Import necessary functions and configuration objects
import {Amplify} from 'aws-amplify';
import awsConfig from '../aws-exports'; // Adjust path as necessary
import ReactGA from 'react-ga';

/**
 * Initialize AWS Amplify with the configuration from your aws-exports.js file.
 */
export function configureAmplify() {
  Amplify.configure(awsConfig);
}

/**
 * Initialize Google Analytics with your tracking ID.
 * Ensure to use environment variables for your tracking ID to keep it secure.
 */
export function initializeAnalytics() {
    const trackingId = process.env.REACT_APP_GA_TRACKING_ID; // Use your env variable
    ReactGA.initialize(trackingId);
}
