import React from 'react';
import './searchForm.css';

export function SearchForm({ searchQuery, setSearchQuery, onSearch }) {
  // This function updates the searchQuery state
  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // This function is triggered when the search button is clicked
  const handleClick = (e) => {
    e.preventDefault(); // Prevent default action to avoid any form submission, if not needed
    onSearch(searchQuery);
    // re render the box
  };
  
  const handleTouchStart = (e) => {
    onSearch(searchQuery); // Call the onSearch function with the current search query
  };

  return (
      <div className='search-form'>
        <input
          type="text"
          placeholder="(e.g. AB1 2CD)"
          value={searchQuery.toUpperCase()}
          onChange={handleChange}
          id='postcode-input-box'
        />
        {/* Use onClick instead of form submit */}
        <button onClick={handleClick} onTouchStart={handleTouchStart}>Search</button>
      </div>
  );
}
