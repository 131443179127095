import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import './GeoChart.css';

function GeoChart({ data, locationValues, onSelectId, propertType }) {
  const chartContainerRef = useRef(null);
  
  const initialWidth = window.innerWidth
  // the viewpoert height is the height of the browser window
  // I want
  const initialHeight = window.innerHeight*0.8

  const [chartDimensions, setChartDimensions] = useState({ width: initialWidth, height: initialHeight});


  const updateChartDimensions = () => {
    if (chartContainerRef.current) {
      const width = chartContainerRef.current.offsetWidth;
      const height = chartContainerRef.current.offsetHeight;
      setChartDimensions({ width, height });
    }
  };
  

  useEffect(() => {
    updateChartDimensions();
    window.addEventListener('resize', updateChartDimensions);
    return () => window.removeEventListener('resize', updateChartDimensions);
  }, []);

  useEffect(() => {
    if (!data || !data.objects || !data.objects['uk-postcode-area']) return;

    const { width, height } = chartDimensions;
    const projection = d3.geoNaturalEarth1()
      .scale(width * 8)
      .center([-2, 53])
      .translate([width / 2, height / 2]);

    const path = d3.geoPath().projection(projection);

    d3.select(chartContainerRef.current).select("svg").selectAll("*").remove();
    const svg = d3.select(chartContainerRef.current).select("svg")
                  .attr("viewBox", `0 0 ${width} ${height}`);

    const uk_postcode_area = topojson.feature(data, data.objects['uk-postcode-area']).features;

    const colorScale = d3.scaleSequential(d3.interpolateRdBu)
      .domain([-20, 20]);

    const g = svg.append("g");

    g.selectAll("path")
       .data(uk_postcode_area)
       .enter()
       .append("path")
       .attr("fill", d => {
        const locationData = locationValues[d.id];
        return locationData ? colorScale(locationData.percent_change) : "#ccc";
      })
       .attr("stroke", "white")
       .attr("stroke-width", 1)
       .attr("d", path)
       .on("click", (event, d) => onSelectId(d.id));

    g.selectAll("text")
       .data(uk_postcode_area)
       .enter()
       .append("text")
       .attr("transform", d => `translate(${path.centroid(d)})`)
       .attr("text-anchor", "middle")
       .attr("alignment-baseline", "central")
       .text(d => d.id)
       .style("font-size", "8px")
       .style("fill", "black")
       .style("pointer-events", "none");

       const zoomHandler = d3.zoom()
       .scaleExtent([1, 15])
      //  
       .translateExtent([[-width*2,-height*2], [width*2, height*2]])
       .on("zoom", (event) => {
         g.attr("transform", event.transform);
         g.selectAll("text").style("font-size", `${12 / event.transform.k}px`);
        //  also change the stroke width
        g.selectAll("path").attr("stroke-width", 1 / event.transform.k);
         
         // Directly store zoom level without using component state
         localStorage.setItem('mapZoomLevel', JSON.stringify(event.transform));
       });
   
     svg.call(zoomHandler);
   
     // Apply stored zoom level
     const storedZoomLevel = JSON.parse(localStorage.getItem('mapZoomLevel'));
     if (storedZoomLevel) {
       svg.call(zoomHandler.transform, d3.zoomIdentity.translate(storedZoomLevel.x, storedZoomLevel.y).scale(storedZoomLevel.k));
     }
   
    }, [data, chartDimensions, locationValues, onSelectId, propertType]);


  return <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }}>

    <svg style={{ width: '100%', height: '100%' }}></svg>
  </div>;
}

export default GeoChart;
