
import fetchOrUpdateUserStatus from '../components/Membership.js';

import {CallToJoin} from '../components/CallsToAction.js';
import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { signOut} from '@aws-amplify/auth';
import { BuyButton } from '../components/BuyButtons.js';
import StripeStatusTable from '../components/StripeStatusTable.js';
import SiteCard from '../components/SiteCard.js';
import { Link } from 'react-router-dom';






import { useAuthenticator } from '@aws-amplify/ui-react';

function ManageSubscription({ userEmail }) {
  // Handler function to open the link
  const handleButtonClick = () => {
    // Open the Stripe billing link in a new tab
    window.open(process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL+'?prefilled_email='+userEmail, '_blank');
  };

  return (
    // Button to manage subscription, onClick event opens the Stripe billing page
    <button onClick={handleButtonClick} className="otta-button">
      Manage Subscription
    </button>
  );
}

const Subscription = ({ stripeStatus, userEmail }) => {

    
  if (!stripeStatus) {
    return (
<div style={{ 
    justifyContent: 'center', 
    alignItems: 'center', 
    textAlign: 'center',
    height: '100%',  // Ensure it takes full height of its container
    width: '100%'    // Ensure it takes full width of its container
}}>
  <SiteCard header={'SUBSCRIPTION DETAILS'} title = {'Support Otta.property'} content={<BuyButton userEmail={userEmail} />}></SiteCard>
   
</div>



    )
  }

  const SubscriptionDetails = (    <div>
      <Row>
    <Col xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' , marginBottom: '1rem'}}>
      <ManageSubscription userEmail={userEmail} />
    </Col>
    <Col xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' , marginBottom: '1rem'}}>
      <p>Clicking this button will open a new window to the stripe billing page.
      Otta.property does not store any payment information.
      </p>
    </Col>
  </Row>
  <Row>
    <div>
      <StripeStatusTable stripeStatus={stripeStatus} />
    </div>
    </Row>

  <Col xs={12} md={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <h3>Thanks for your support</h3>
  </Col>


</div>
  );

  // Check if subscription is active and render appropriate message
  return (
    <SiteCard header={'SUBSCRIPTION DETAILS'} title = {'Subscription Details'} content={SubscriptionDetails} />

    
  );
};


const handleSignOut = async () => {
  try {
    await signOut();
  } catch (error) {
    console.error('Error signing out: ', error);
  }
};

const AccountPage = () => {
  const { user, isLoadingUser } = useAuthenticator();
  const [userEmail, setUserEmail] = useState(null); // State to store user email
  const [status, setStatus] = useState(null);
  const [stripeStatus, setStripeStatus] = useState(null);
  const [, setStripeSubscription] = useState(null); // State to store user status [null, loading, error, data
  const lastUserIdRef = useRef(null); // Ref to track the last user id processed

  useEffect(() => {
    const processUserStatus = async () => {
      // Only process if user exists, status is null, and user ID has changed
      if (user && status === null && lastUserIdRef.current !== user.userId) {
        try {
          const result = await fetchOrUpdateUserStatus(user);
          setStatus(result);

            if (result.stripe && result.stripe.stripe_sub_status === 'active') {
              setStripeSubscription(true);
          }

          if (result.stripe) {
            setStripeStatus(result.stripe);
          }
          if (result.email) {
              setUserEmail(result.email);
          }
          lastUserIdRef.current = user.userId; // Update last processed user id
        } catch (error) {
          console.error("Processing error:", error);
        }
      }
    };

    processUserStatus();
  }, [user, status]);


  if (isLoadingUser) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }



  if (!user) {
    return (
      <CallToJoin />
    );
  }

  const accountContent = (
    <div style={{ textAlign: 'left' , height: '100%'}}>
            <p>Enjoy the benefits of your account:</p>
            <ul>
            
            <li><a href="https://forms.gle/2G8ufLwat9qS8Fh48">Contribute through our <strong>Feedback Form</strong> to help us improve.</a></li>
            <li>Stay informed with any updates.</li>
            <hr />
            <li>If you're a <b>premium subscriber</b>, you can:
                <Container>
                    <ul>
                        <li>Access the <Link to="/trends">latest trends</Link> in the property market.</li>
                        <li>Access the <Link to="/machine-learning-property-price-predictor">Predictor</Link> tool.</li>
                    </ul>
                </Container>
            </li>
        </ul>
        
        <Container style={{ textAlign: 'center' ,marginTop: '1rem' , marginBottom: '1rem'}}>
                <button className="otta-button" onClick={handleSignOut}>Sign Out</button>
              </Container>
    </div>

          
  );




  return (

    



  <Container>
    <Row className="align-items-stretch justify-content-center" style={{ marginBottom: '2rem' }}>
    <Col md={6}>
    <SiteCard header={'WELCOME'} title={'Welcome to your account'} content={accountContent} />
    
      </Col>
      <Col md={6}>
        <Subscription stripeStatus={stripeStatus} userEmail={userEmail} />
      </Col>
    

    </Row>
    {/* <Row>
      <Col md={6}>
        <SiteCard header={'PREMIUM FEATURES'} title={'Subscribe for less than the price of a cup of coffee'} content={premiumContent} />
      </Col>


    </Row> */}


    
  </Container>
  );
}

export default AccountPage;