import React from 'react';
import Plot from 'react-plotly.js';
import './MapPlot.css';



export function MapPlot({ mapData, mapCenter, zoomLevel, colorBarRange, updateMapView, mapboxStyle, colorscalePalette }) {

    const plotData = {
        type: 'scattermapbox',
        mode: 'markers',
        marker: {
            size: 10,
            color: mapData.map(d => d.price),
            cmin: colorBarRange[0], // Keep original color bar min
            cmax: colorBarRange[1], // Keep original color bar max
            colorscale:
            colorscalePalette,
            colorbar: {
                title: 'Price (£)',
                x: 0.025,
                xanchor: 'left',
                xpad: 0,
                y: 0.82,
                yanchor: 'middle',
                ypad: 0,
                thickness: 10,
                len: 0.3,
                outlinecolor: 'black',
                outlinewidth: 1,
                tickfont: {
                    size: 10,
                    color: 'black'
                },
                // give it semi transparent background
                bgcolor: 'rgba(255, 255, 255, 0.8)',
                // set the ticks on a 100k interval
                tickmode: 'array',
                // mark each 100k
                tickvals: { start: Math.floor(colorBarRange[0] / 100000) * 100000, end: Math.ceil(colorBarRange[1] / 100000) * 100000, size: 100000}
            }
        },
        lat: mapData.map(d => d.latitude),
        lon: mapData.map(d => d.longitude),
        text: mapData.map(d => `Price: £${d.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<br>Date: ${d.month_year}<br>Type: ${d.type}<br>Postcode: ${d.post_code}<br>Address: ${d.address}`),
        hoverinfo: 'text',
    };

    const plotLayout = {
        autosize: true,
        hovermode: 'closest',
        mapbox: {
            style: mapboxStyle || 'open-street-map',
            center: mapCenter,
            zoom: zoomLevel,
        },
        margin: {
            l: 0,
            r: 0,
            b: 0,
            t: 0,
            pad: 4
        }
    };

    const plotStyle = {
        width: '100%',
        height: '100vh',
    };

    const plotConfig = {
        displayModeBar: false
    };

   // Adjusted for clarity and correctness
    const handleRelayout = (eventData) => {
      if (eventData['mapbox.center'] && eventData['mapbox.zoom']) {
        const newCenter = {
          lat: eventData['mapbox.center'].lat,
          lon: eventData['mapbox.center'].lon,
        };
        const newZoom = eventData['mapbox.zoom'];

        // If mapbox._derived is available, use it to update bounds
        if (eventData['mapbox._derived']) {
          const coordinates = eventData['mapbox._derived'].coordinates;
          // Example logic to extract bounds, adjust according to eventData structure
          const newBounds = {
            max_lat: coordinates[0][1],
            min_lat: coordinates[2][1],
            max_lon: coordinates[1][0],
            min_lon: coordinates[0][0],
            
          };

          // Update mapView with both center, zoom, and bounds
          updateMapView({ ...newBounds, zoom: newZoom, center: newCenter });
        } else {
          // If no bounds, update only center and zoom
          updateMapView({ center: newCenter, zoom: newZoom });
        }
      }
    };



    return (
<div style={{ position: 'relative', touchAction: 'none' }}>


            <Plot
                data={[plotData]} 
                layout={plotLayout}
                style={plotStyle}
                config={plotConfig}
                onRelayout={handleRelayout}
            />
    </div>
    );
}