import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
  BarElement,
  BarController
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

const verticalLinePlugin = {
  id: 'verticalLine',
  afterDraw: (chart) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const activePoint = chart.tooltip._active[0];
      const ctx = chart.ctx;
      const x = activePoint.element.x;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'rgba(0,0,0,0.5)';
      ctx.stroke();
      ctx.restore();
    }
  }
};

ChartJS.register(
  CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, 
  TimeScale, BarElement, BarController, verticalLinePlugin,
  {
    id: 'customSubtitle',
    afterDraw(chart, args, options) {
      const {ctx} = chart;
      ctx.save();
      ctx.font = options.font || '8px Arial';
      ctx.textAlign = 'center';
      ctx.fillStyle = options.color || '#666';
      ctx.fillText(options.text || '', chart.width / 2, options.position || 50);
      ctx.restore();
    }
  }
);

const ChartJSComponent = ({ data, metadata }) => {
  const chartConfig = {
    responsive: true,
    data: {
      labels: data.map((d) => d.date),
      datasets: [
        {
          type: 'bar',
          label: 'Count',
          data: data.map((d) => d.count),
          borderColor: 'black', // This can be removed if border is not needed at all
          backgroundColor: 'rgba(173, 216, 230, 0.8)', // Light blue color (similar to LightSkyBlue)
          borderWidth: 0, // Set border width to 0 to remove the border
          yAxisID: 'y1',
          showInLegend: true,
          order: 3
        },        
        {
          label: 'Smoothed',
          data: data.map((d) => d.geometric_mean_12_month_window/metadata.scale_factor),
          fill: false,
          pointRadius: 0,
          borderColor: 'rgba(0,0,0,1)',
          showInLegend: true,
          order: 2,
          borderWidth: 3,
      
        },
        {
          label: 'Raw',
          data: data.map((d) => d.geometric_mean / metadata.scale_factor),
          fill: false,
          pointRadius: 0,
          borderColor: 'rgba(0, 0,0,1)',
          showInLegend: true,
          order: 1,
          borderWidth: 1,
        },
      ],
    },
    options: {
      animations: false,
      aspectRatio: 1.5,
      layout: {
        padding: {
          top: 30,
          right: -6,
          bottom: 0,
          left: -6,
        },
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            filter: function(item, chart) {
              // You can add filtering logic here if needed
            },
          }
        },
        customSubtitle: {
          display: true,
          text: metadata.subtitle,
          font: '12px Arial',
          color: '#666',
          position: 20
        },
        tooltip: {
          mode: 'nearest',
          intersect: false,
          displayColors: false,  // Continue to disable the display of the color box in the tooltip
          callbacks: {
            label: function(context) {
              // Fetch the index of the hovered item
              const dataIndex = context.dataIndex;
              // Access all datasets
              const datasets = context.chart.data.datasets;
              // Extract the values for min, middle, and max estimates
              const raw = datasets[2].data[dataIndex];  
              const smoothed = datasets[1].data[dataIndex]; 
              const count = datasets[0].data[dataIndex];
              
              // Format values using Intl.NumberFormat, adjusting by the scale factor
              const formattedRaw = new Intl.NumberFormat('en-GB', { 
                style: 'currency', 
                currency: 'GBP',
                minimumFractionDigits: 0, 
                maximumFractionDigits: 0 
              }).format(raw);
        
              const formattedSmoothed = new Intl.NumberFormat('en-GB', { 
                style: 'currency', 
                currency: 'GBP',
                minimumFractionDigits: 0, 
                maximumFractionDigits: 0 
              }).format(smoothed);
        
              const formattedCount = new Intl.NumberFormat('en-GB', {
                style: 'decimal',
                maximumFractionDigits: 0
              }).format(count);

              // if the scale factor is 1000, the currency symbol will be k
              const RawLabel = metadata.scale_factor === 1000 ? `${formattedRaw}k` : formattedRaw;
              const SmoothedLabel = metadata.scale_factor === 1000 ? `${formattedSmoothed}k` : formattedSmoothed;
              // Construct and return the custom tooltip label
              // if the scale factor is 1, the currency symbol will be £ 
              return [`Raw: ${RawLabel}`,`Smoothed: ${SmoothedLabel}`,`Count: ${formattedCount}`];
            }
          }
        }
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
            tooltipFormat: 'yyyy MMM',
            displayFormats: {
              day: 'MMM yy',
            },
          },
          ticks: {
            autoSkip: false,
            maxRotation: 90,
            minRotation: 90,
          },
          grid: {
            display: false,
          },
          min: data[0].date,
          max: metadata.x_max,
        },
        y: {
          type: 'linear',
          position: 'left',
          grid: {
            display: true,
          },
          min: Math.floor(metadata.y_min/metadata.scale_factor / 50) * 50,
          max: Math.ceil(metadata.y_max/metadata.scale_factor / 50) * 50,
          title: {
            display: true,
            text: metadata.y1_title,
          }
        },
        y1: {
          type: 'linear',
          position: 'right',
          grid: {
            display: false,
          },
          title: {
            display: true,
            text: metadata.y2_title,
          }
        },
      },
    },
  };
  
  return <Line data={chartConfig.data} options={chartConfig.options} />;
};

export default ChartJSComponent;