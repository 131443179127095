import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import SiteCard from '../components/SiteCard';
import SEOComponent from '../components/SEOComponent';
import { FaChartLine, FaMapMarkedAlt, FaRobot } from 'react-icons/fa';
import { Authenticator } from '@aws-amplify/ui-react';

import {AdComponent} from '../components/AdComponent';

import './Home.css';

const Home = () => {
    const rowRefs = useRef([]);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            rowRefs.current.forEach((row, index) => {
                if (row) {
                    const rect = row.getBoundingClientRect();
                    const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
                    row.style.opacity = isVisible ? '1' : '0.5';
                    row.style.transform = isVisible ? 'scale(1)' : 'scale(0.98)';
                    row.style.transitionDelay = `${index * 0.1}s`;
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <SEOComponent 
                title="Otta.property home page" 
                description="Beat the property market with Data Science" 
                url="https://otta.property/" 
                image="https://otta.property/trends2.webp" 
            />

            <Container 
                fluid 
                className="hero-section d-flex align-items-center justify-content-center px-4"
                style={{ 
                    minHeight: '100vh',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)'
                }}
            >
                <Row className="justify-content-center w-100">
                    <Col xs={12} md={8} lg={6} className="text-center">
                        <h1 className="display-4 text-white mb-4">
                            PROPERTY DATA SCIENCE MADE EASY
                        </h1>
                        <p className="lead text-white mb-5">
                            Compare property prices and trends in England and Wales. 
                            Get <b>free</b> access to a machine learning property price predictor.
                            Further unlimited use is available for subscribers at £3.99 per month.
                        </p>
                        <Authenticator className="mb-4" />
                        <Link to="/map">
                            <button className="btn btn-light btn-lg">
                                Explore the Map
                            </button>
                        </Link>
                    </Col>
                </Row>
            </Container>

      <AdComponent />


            <Container className="d-flex flex-column align-items-center">
            <Row 
                    ref={(el) => rowRefs.current[0] = el}
                    className="justify-content-center transition-row my-5 mx-0" 
                    style={{ width: '100%'}}
                >
                    <Col md={12}>
                        <SiteCard 
                            header={<><FaMapMarkedAlt className="mr-2" /> MAP</>}
                            content={
                                <Container fluid className="h-100">
                                    <Row className="h-100 align-items-center" style={{ minHeight: '40vh' }}>
                                        <Col md={6} className="d-flex flex-column justify-content-center">
                                        <h3 className="text-center" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                        Interactive Property Map</h3>
                                            <p>Explore our interactive map showcasing property data across England and Wales. Filter by property type, date, and price to visualise market trends in different areas.</p>
                                            <div className="text-center" style={{ marginTop: '1rem' , marginBottom: '1rem'}}>
                                                <Link to="/map">
                                                    <button className="otta-button mt-3">
                                                        View Interactive Map
                                                    </button>
                                                </Link>
                                            </div>
                                        </Col>
                                        <Col md={6} className="d-flex align-items-center justify-content-center">
                                            <img src="/map.webp" alt="Interactive Map Preview" className="img-fluid rounded shadow site-card-image" />
                                        </Col>
                                    </Row>
                                </Container>
                            }
                        />
                    </Col>
                </Row>
            </Container>


            <AdComponent />
            <Container className="d-flex flex-column align-items-center">



                
            <Row 
                ref={(el) => rowRefs.current[2] = el}
                className="justify-content-center transition-row my-5 mx-0"  
                style={{ width: '100%' }}
            >
                <Col md={12}>
                    <SiteCard 
                        header={<><FaRobot className="mr-2" /> REVOLUTIONARY ML PREDICTION TOOL</>}
                        content={
                            <Container fluid className="h-100">
                                <Row className="h-100 align-items-center" style={{ minHeight: '40vh' }}>
                                    <Col md={6} className="d-flex flex-column justify-content-center">
                                        <h3 className="text-center" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                            Free Machine Learning Property Price Predictor
                                        </h3>
                                        <p>
                                            <strong>Groundbreaking Technology:</strong> Introducing the market's first-ever machine learning tool for property prediction.
                                        </p>
                                        <p>
                                            Harness the power of advanced algorithms to:
                                        </p>
                                        <ul>
                                            <li>Predict property values with unprecedented accuracy</li>
                                            <li>Make data-driven decisions</li>
                                        </ul>
                                        <p>
                                            Stay ahead of the curve with this revolutionary tool, exclusively available here at otta.property.
                                        </p>
                                        <div className="text-center">
                                            <Link to="/machine-learning-property-price-predictor">
                                                <button className="otta-button mt-3">
                                                    Try it for Free Now!
                                                </button>
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col md={6} className="d-flex align-items-center justify-content-center">
                                        <img src="/predictor.webp" alt="First-Ever ML Prediction Tool" className="img-fluid rounded shadow site-card-image" />
                                    </Col>
                                </Row>
                            </Container>
                        }
                    />
                </Col>
            </Row>

            </Container>


            <AdComponent />
            
            <Container className="d-flex flex-column align-items-center">


            <Row 
                    ref={(el) => rowRefs.current[1] = el}
                    className="justify-content-center transition-row my-5 mx-0" 
                    style={{ width: '100%'}}
                >
                    <Col md={12}>
                        <SiteCard 
                            header={<><FaChartLine className="mr-2" /> TRENDS</>}
                            content={
                                <Container fluid className="h-100">
                                    <Row className="h-100 align-items-center" style={{ minHeight: '40vh' }}>
                                        <Col md={6} className="d-flex align-items-center justify-content-center">
                                            <img src="/trend.webp" alt="Property Trends Preview" className="img-fluid rounded shadow site-card-image" />
                                        </Col>
                                        <Col md={6} className="d-flex flex-column justify-content-center">

                                            <h3 className="text-center" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                                The First Ever ML based Property Index
                                            </h3>
                                            <p>Stay ahead of the market with our comprehensive trend analysis. Get insights into price movements, market hotspots, and emerging patterns in the property landscape.
                                                Made possible by our cutting-edge machine learning algorithms, our trend analysis tool is the first of its kind in the market.</p>
                                            <div className="text-center" style={{ marginTop: '1rem' , marginBottom: '1rem'}}>
                                                <Link to="/trends">
                                                    <button className="otta-button mt-3">
                                                        Explore Trends
                                                    </button>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                        />
                    </Col>
                </Row>  
                
                       
            
            
          
                

               
            </Container>

            <AdComponent />


            <Container fluid className="cta-section py-5 text-white text-center">
                <h2 className="mb-4">Ready to gain the edge in property market?</h2>
                <Link to="/members">
                    <button 
                        className="btn btn-light btn-lg"
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                    >
                        Get Started Now
                        <span className={`ml-2 ${isHovering ? 'animate__animated animate__headShake' : ''}`}>
                            →
                        </span>
                    </button>
                </Link>
            </Container>


      <AdComponent />

        </>
    );
}

export default Home;