import React from 'react';
import { Container } from 'react-bootstrap';
import SEOComponent from '../components/SEOComponent';
import SiteCard from '../components/SiteCard';

const termsAndConditions = {
    lastUpdated: "2024-Jun-06",
    introduction: "Please read these terms and conditions before using Our Service.",
    interpretationAndDefinitions: {
      interpretation: "Words with capital letters have specific meanings defined here. These definitions apply whether they are singular or plural.",
      definitions: {
        Affiliate: "An entity that controls, is controlled by, or is under common control with a party, where 'control' means ownership of 50% or more of the shares, equity interest, or voting securities.",
        Account: "A unique account created for You to access our Service or parts of our Service.",
        Company: "Inorite Ltd - All rights reserved. Company No. 15762027. Referred to as 'the Company', 'We', 'Us' or 'Our' in this Agreement.",
        Country: "United Kingdom",
        Content: "Content such as text, images, or other information posted, uploaded, linked to, or made available by You, in any form.",
        Device: "Any device that can access the Service like a computer, cellphone, or tablet.",
        Feedback: "Feedback, innovations, or suggestions sent by You about our Service.",
        Service: "The Website.",
        TermsAndConditions: "These Terms and Conditions that form the entire agreement between You and the Company about the use of the Service. Also called 'Terms' or 'Terms of Use'.",
        ThirdPartySocialMediaService: "Any services or content (including data, information, products, or services) provided by a third-party that may be displayed, included, or made available by the Service.",
        Website: "otta.property, accessible from https://otta.property/",
        You: "The person accessing or using the Service, or the company, or other legal entity on whose behalf the individual is accessing or using the Service."
      }
    },
    acknowledgment: {
      description: "These Terms and Conditions govern the use of this Service and the agreement between You and the Company. They outline the rights and obligations of all users regarding the Service.",
      conditions: [
        "Your access to and use of the Service depends on Your acceptance of and compliance with these Terms and Conditions.",
        "These Terms and Conditions apply to all visitors, users, and others who access or use the Service.",
        "By accessing or using the Service You agree to be bound by these Terms and Conditions.",
        "If You disagree with any part of these Terms and Conditions, You may not access the Service.",
        "You must be over the age of 18 to use the Service.",
        "Your access to and use of the Service also depends on Your acceptance of and compliance with the Company's Privacy Policy."
      ]
    },
    userAccounts: {
      description: "When You create an account with Us, You must provide accurate, complete, and current information at all times. Failure to do so is a breach of the Terms, which may result in immediate termination of Your account.",
      responsibilities: [
        "You are responsible for safeguarding Your password and any activities under Your password, whether with Our Service or a Third-Party Social Media Service.",
        "You agree not to disclose Your password to any third party.",
        "You must notify Us immediately if You become aware of any breach of security or unauthorized use of Your account.",
        "You may not use as a username the name of another person or entity that is not lawfully available for use, a name or trademark subject to rights of another person or entity without proper authorization, or a name that is offensive, vulgar, or obscene."
      ]
    },
    intellectualProperty: {
      description: "The Service and its original content (excluding Content provided by You or other users), features, and functionality are the exclusive property of the Company and its licensors.",
      protection: [
        "The Service is protected by copyright, trademark, and other laws of the Country and foreign countries.",
        "Our trademarks and trade dress may not be used without the prior written consent of the Company."
      ]
    },
    feedbackToUs: {
      description: "You assign all rights, title, and interest in any Feedback You provide to the Company. If this assignment is ineffective, You agree to give the Company a non-exclusive, perpetual, irrevocable, royalty-free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify, and exploit such Feedback without restriction."
    },
    linksToOtherWebsites: {
      description: "Our Service may contain links to third-party websites or services not owned or controlled by the Company.",
      disclaimer: [
        "The Company has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services.",
        "You agree that the Company is not responsible or liable for any damage or loss caused by or in connection with the use of or reliance on any content, goods, or services available on or through such websites or services.",
        "We strongly advise You to read the terms and conditions and privacy policies of any third-party websites or services You visit."
      ]
    },
    termination: {
      description: "We may terminate or suspend Your Account immediately, without notice or liability, for any reason, including if You breach these Terms and Conditions.",
      effect: "Upon termination, Your right to use the Service will end immediately. If You wish to terminate Your Account, You may stop using the Service."
    },
    limitationOfLiability: {
      description: "The Company's total liability, including any suppliers, is limited to the amount paid by You through the Service or 100 GBP if You haven't paid anything.",
      limitations: [
        "To the maximum extent allowed by law, the Company and its suppliers are not liable for any special, incidental, indirect, or consequential damages, including lost profits, data loss, business interruption, personal injury, or privacy loss, even if advised of the possibility of such damages.",
        "Some jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, so some of the above limitations may not apply. In these jurisdictions, liability will be limited to the greatest extent permitted by law."
      ]
    },
    disclaimer: {
      description: "The Service is provided 'AS IS' and 'AS AVAILABLE' without warranty of any kind. To the fullest extent permitted by law, the Company and its Affiliates and licensors disclaim all warranties, express, implied, statutory, or otherwise, including implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.",
      exclusions: [
        "The Company does not guarantee that the Service will meet Your needs, achieve intended results, be compatible or work with other software, systems, or services, operate without interruption, meet performance or reliability standards, or be error-free or that errors can or will be corrected.",
        "The Company makes no warranty: (i) about the operation or availability of the Service, or the information, content, and materials or products included on it; (ii) that the Service will be uninterrupted or error-free; (iii) about the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, content, or e-mails sent from the Company are free of viruses, malware, or other harmful components.",
        "Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on statutory rights of consumers, so some or all of the above exclusions and limitations may not apply. In such cases, the exclusions and limitations will apply to the greatest extent enforceable under law."
      ]
    },
    dataProtectionAndPrivacy: {
        description: "We are committed to protecting your personal data. We collect, use, and protect your personal data in accordance with applicable data protection laws, including the General Data Protection Regulation (GDPR) for EU customers.",
        details: [
          "We strive to collect data on a minimal basis and only for the purposes outlined in our Privacy Policy.",
          "This data is used to provide and improve our Service, process payments, and communicate with you.",
          "We implement appropriate technical and organisational measures to safeguard your data against unauthorised access, alteration, disclosure, or destruction.",
          "For more detailed information, please refer to our Privacy Policy.",
          "You have the rights to access, correct, or delete your personal data. You can exercise these rights by contacting otta.property via the feedback form on the website."
        ]
      },
      paymentTerms: {
        description: "Our Service involves payments that may include subscription fees. Payments are processed through secure third-party payment processors.",
        details: [
          "Subscription models include monthly or annual plans, which will automatically renew unless cancelled.",
          "You may cancel your subscription at any time through your account.",
          "Cancellations will take effect at the end of the current billing cycle.",
          "Refunds, if applicable, are processed according to our refund policy."
        ]
      },
      serviceLevelsAndDowntime: {
        description: "We strive to maintain a high level of service uptime. However, we cannot guarantee uninterrupted access to the Service.",
        details: [
          "In the event of scheduled maintenance or unexpected downtime, we will make reasonable efforts to notify you in advance.",
          "Compensation for downtime will be considered on a case-by-case basis and may include extending your subscription period."
        ]
      },
      renewalAndCancellationPolicy: {
        description: "Subscriptions are automatically renewed at the end of each billing cycle unless cancelled.",
        details: [
          "You can cancel your subscription at any time through the account page. This is managed by the third-party payment processor: Stripe.",
          "To avoid being charged for the next billing cycle, cancellations must be made at least 24 hours before the renewal date.",
          "We reserve the right to terminate or suspend your subscription if you breach these Terms and Conditions.",
          "Upon termination, your access to the Service will cease immediately."
        ]
      },
      disputeResolutionSpecifics: {
        description: "If you have any disputes regarding the Service, we encourage you to contact us first to resolve the issue informally.",
        details: [
          "If a resolution cannot be reached, disputes will be resolved through arbitration or mediation as agreed upon by both parties.",
          "The place of arbitration or mediation will be determined based on mutual agreement, and the costs will be shared equally unless otherwise specified by the arbitrator or mediator."
        ]
      },
      rightsToChanges: {
        description: "We reserve the right to modify or discontinue any part of the Service at any time.",
        details: [
          "Significant changes to features or functionality will be communicated to users via email or through notifications on the Service.",
          "Your continued use of the Service after such changes will constitute your acceptance of the new terms.",
          "If you do not agree with the changes, you may terminate your subscription as outlined in the Renewal and Cancellation Policy."
        ]
      },
      
    governingLaw: {
      description: "The laws of the United Kingdom, excluding its conflicts of law rules, govern these Terms and Your use of the Service. Your use of the Application may also be subject to other local, national, or international laws."
    },
    disputesResolution: {
      description: "If You have any concern or dispute about the Service, You agree to try to resolve it informally by contacting the Company first."
    },
    euUsers: {
      description: "If You are a European Union consumer, You will benefit from any mandatory provisions of the law of the country where You live."
    },
    ukLegalCompliance: {
      description: "You represent and warrant that (i) You are not located in a country subject to the United Kingdom government embargo, or that has been designated by the United Kingdom government as a 'terrorist supporting' country, and (ii) You are not listed on any United Kingdom government list of prohibited or restricted parties."
    },
    severabilityAndWaiver: {
      severability: "If any provision of these Terms is found to be unenforceable or invalid, it will be changed and interpreted to accomplish the objectives of that provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect.",
      waiver: "Failure to exercise a right or require performance of an obligation under these Terms does not affect a party's ability to exercise such right or require such performance later, nor does it mean the waiver of a breach constitutes a waiver of any later breach."
    },
    translationInterpretation: {
      description: "These Terms and Conditions may have been translated if We made them available to You. You agree that the original English text will prevail in the case of a dispute."
    },
    changesToTermsAndConditions: {
      description: "We can modify or replace these Terms at any time. If a revision is material, We will try to give at least 30 days' notice before new terms take effect. What counts as a material change is decided by Us.",
      agreement: "By continuing to access or use Our Service after revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, please stop using the Service."
    },
    contactUs: {
      description: "If you have any questions about these Terms and Conditions, You can contact us using the feedback form on the website."
    }
  };
  

const TermsAndConditions = () => {
  return (
    <>
  <SEOComponent
    title={'Otta.property Terms and Conditions'}
    description={'Terms and Conditions for otta.property website'}
  />
    <Container style={{ width: "80%", marginBottom: "2rem", fontSize: ".8rem" }}>
          <SiteCard header="Terms and Conditions" title="Terms and Conditions for otta.property" content= {
          <>


          <h2>Terms and Conditions</h2>
          <p><strong>Last Updated:</strong> {termsAndConditions.lastUpdated}</p>
          <p>{termsAndConditions.introduction}</p>
          
          <h4>Interpretation and Definitions</h4>
          <h5>Interpretation</h5>
          <p>{termsAndConditions.interpretationAndDefinitions.interpretation}</p>
          
          <h5>Definitions</h5>
            <ul>
            {Object.entries(termsAndConditions.interpretationAndDefinitions.definitions).map(([term, definition]) => (
                <li key={term}>
                <p><strong>{term}</strong> {definition}</p>
                </li>
            ))}
            </ul>

          
          <h4>Acknowledgment</h4>
          <p>{termsAndConditions.acknowledgment.description}</p>
            <ul>
            {termsAndConditions.acknowledgment.conditions.map((condition, index) => (
                <li key={index}>{condition}</li>
            ))}
            </ul>

          
          <h4>User Accounts</h4>
          <p>{termsAndConditions.userAccounts.description}</p>
            <ul>
            {termsAndConditions.userAccounts.responsibilities.map((responsibility, index) => (
                <li key={index}> {responsibility}</li>
            ))}

            </ul>

          
          <h4>Intellectual Property</h4>
          <p>{termsAndConditions.intellectualProperty.description}</p>
            <ul>
            {termsAndConditions.intellectualProperty.protection.map((protection, index) => (
                <li key={index}> {protection}</li>
            ))}
            </ul>

          
          <h4>Your Feedback to Us</h4>
          <p>{termsAndConditions.feedbackToUs.description}</p>
          
          <h4>Links to Other Websites</h4>
          <p>{termsAndConditions.linksToOtherWebsites.description}</p>
            <ul>
            {termsAndConditions.linksToOtherWebsites.disclaimer.map((disclaimer, index) => (
                <li key={index}> {disclaimer}</li>
            ))}
            </ul>

          
          <h4>Termination</h4>
          <p>{termsAndConditions.termination.description}</p>
          <p>{termsAndConditions.termination.effect}</p>
          
          <h4>Limitation of Liability</h4>
          <p>{termsAndConditions.limitationOfLiability.description}</p>
            <ul>
            {termsAndConditions.limitationOfLiability.limitations.map((limitation, index) => (
                <li key={index}> {limitation}</li>
            ))}
            </ul>

          <h4>"AS IS" and "AS AVAILABLE" Disclaimer</h4>
          <p>{termsAndConditions.disclaimer.description}</p>
            <ul>
            {termsAndConditions.disclaimer.exclusions.map((exclusion, index) => (
                <li key={index}>
                <p>{exclusion}</p>
                </li>
            ))}
            </ul>

            <h4>Data Protection and Privacy</h4>
            <p>{termsAndConditions.dataProtectionAndPrivacy.description}</p>
            <ul>
            {termsAndConditions.dataProtectionAndPrivacy.details.map((detail, index) => (
                <li key={index}> {detail}</li>
            ))}

            </ul>

            <h4>Payment Terms</h4>
            <p>{termsAndConditions.paymentTerms.description}</p>
            <ul>
            {termsAndConditions.paymentTerms.details.map((detail, index) => (
                <li key={index}> {detail}</li>
            ))}
            </ul>

            <h4>Service Levels and Downtime</h4>
            <p>{termsAndConditions.serviceLevelsAndDowntime.description}</p>
            <ul>
            {termsAndConditions.serviceLevelsAndDowntime.details.map((detail, index) => (
                <li key={index}> {detail}</li>
            ))}
            </ul>

            <h4>Renewal and Cancellation Policy</h4>
            <p>{termsAndConditions.renewalAndCancellationPolicy.description}</p>
            <ul>
            {termsAndConditions.renewalAndCancellationPolicy.details.map((detail, index) => (
                <li key={index}> {detail}</li>
            ))}

            </ul>

            <h4>Dispute Resolution Specifics</h4>
            <p>{termsAndConditions.disputeResolutionSpecifics.description}</p>
            <ul>
            {termsAndConditions.disputeResolutionSpecifics.details.map((detail, index) => (
                <li key={index}> {detail}</li>
            ))}
            </ul>

            <h4>Rights to Changes</h4>
            <p>{termsAndConditions.rightsToChanges.description}</p>
            <ul>
            {termsAndConditions.rightsToChanges.details.map((detail, index) => (
                <li key={index}> {detail}</li>
            ))}
            </ul>
            

          
          <h4>Governing Law</h4>
          <p>{termsAndConditions.governingLaw.description}</p>
          
          <h4>Disputes Resolution</h4>
          <p>{termsAndConditions.disputesResolution.description}</p>
          
          <h4>For European Union (EU) Users</h4>
          <p>{termsAndConditions.euUsers.description}</p>
          
          <h4>United Kingdom Legal Compliance</h4>
          <p>{termsAndConditions.ukLegalCompliance.description}</p>
          
          <h4>Severability and Waiver</h4>
          <h5>Severability</h5>
          <p>{termsAndConditions.severabilityAndWaiver.severability}</p>
          
          <h5>Waiver</h5>
          <p>{termsAndConditions.severabilityAndWaiver.waiver}</p>
          
          <h4>Translation Interpretation</h4>
          <p>{termsAndConditions.translationInterpretation.description}</p>
          
          <h4>Changes to These Terms and Conditions</h4>
          <p>{termsAndConditions.changesToTermsAndConditions.description}</p>
          <p>{termsAndConditions.changesToTermsAndConditions.agreement}</p>
          
          <h4>Contact Us</h4>
          <p>{termsAndConditions.contactUs.description}</p>
          </>
          } />

    </Container>
    </>
  );
};

export default TermsAndConditions;
