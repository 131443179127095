// src/pages/Blog.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import BlogCard from '../components/BlogCard';
import SEOComponent from '../components/SEOComponent';

import blogPosts from '../blog/BlogPosts';


const Blog = () => {

  // sort the blogposts backwards
  blogPosts.sort((a, b) => b.id - a.id);
  return (
    <div>

      <SEOComponent title="Blog"
        description="Welcome to the Blog Page"
        url="https://www.otta.property/blog"
        image="https://www.otta.property/otta_logo2.pmg"
      />
      <Row style={{ padding: '2rem', margin: '2rem', borderRadius: '10px', textAlign: 'center' }}>
      <Col>
                      <h4 style={{ color: 'white', wordWrap: 'break-word' , fontWeight:200}}>
                    otta.property
                </h4>
                
      <h1  style={{
                    fontSize: '3rem',
                    wordWrap: 'break-word',
                    color: 'white',
                }}>Blog</h1>
      <br />
      </Col>
      </Row>
      <Container style={{ marginTop: '2rem', marginBottom: '2rem' }}>
        <Row className="justify-content-center">
          {blogPosts.map(blog => (
            <Col sm={12} md={6} lg={4} key={blog.id}> {/* Adjust column sizes as needed */}
              <BlogCard
                title={blog.title}
                summary={blog.content} // Adjust according to your BlogCard's props
                link={`/${blog.link}`}
                image={blog.image}
              />
              <br />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Blog;
