import React, { useState, useEffect } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';
import { useCallback } from 'react';


import GeoChart from '../../components/GeoChart';
import SEOComponent from '../../components/SEOComponent';

import './Trends.css';
import SiteCard from '../../components/SiteCard';
import RawDataTab from './RawDataTab';
import IndexDataTab from './IndexDataTab';
import BlurbTab from './BlurbTab';

import {AdComponent, SmallAdComponent} from '../../components/AdComponent'; 


const Trends = () => {
  const [selectedCounty, setSelectedCounty] = useState('OX');
  const [rawPropertyType, setrawPropertyType] = useState('All');
  const [indexPropertyType, setIndexPropertyType] = useState('All');
  const [choroplethData, setChoroplethData] = useState(null);
  const [rawLocationValues, setRawLocationValues] = useState({});
  const [indexLocationValues, setIndexLocationValues] = useState({});
  const [mapData, setMapData] = useState({});
  const [activeTab, setActiveTab] = useState('blurb');

  // Update map data when switching tabs
  useEffect(() => {
    setMapData(activeTab === 'raw' ? rawLocationValues : indexLocationValues);
  }, [activeTab, rawLocationValues, indexLocationValues]);




  const handleRawLocationValuesChange = useCallback((newValues) => {
    setRawLocationValues(newValues);
  }, []);

  const handleIndexLocationValuesChange = useCallback((newValues) => {
    setIndexLocationValues(newValues);
  }, []);

  const handleTabChange = useCallback((newTab) => {
    setActiveTab(newTab);
  }, []);


  const handleRawPropertyTypeChange = useCallback((newType) => {
    setrawPropertyType(newType);
  }, []);

  const handleIndexPropertyTypeChange = useCallback((newType) => {
    setIndexPropertyType(newType);
  }, []);


  useEffect(() => {
    const basePath = window.location.hostname === 'localhost' ? 'data' : 'trend';
    fetch(`${basePath}/uk_postcode_areas.json`)
      .then(response => response.ok ? response.json() : Promise.reject('Network response was not ok'))
      .then(setChoroplethData)
      .catch(error => console.error('Fetch error:', error));
  }, []);


  return (
    <>

      <SEOComponent
        title={'Otta.property Trends'}
        description={'Property trends in England and Wales'}
        url={'https://otta.property/trends'}
        image={'https://otta.property/trends2.webp'}
      />
      

      <SmallAdComponent />


    <Container fluid className="trends-container">

    <Row style={{ width: '100%', margin: '0', backgroundColor: 'none', padding: '0'}}>
    <Col md={5} xs={12} className="geo-chart-container">
      <GeoChart
                            data={choroplethData}
                            locationValues={mapData}
                            onSelectId={setSelectedCounty}
                            selectedCounty={selectedCounty}
                            propertyType={rawPropertyType || indexPropertyType}
                        />
                        </Col>
    <Col md={7} sm={12} className='trends-column'>
    <SiteCard header={'Otta.property Trends'} content={
      <Tabs defaultActiveKey="blurb" id="property-data-tabs" style={{marginTop: '0.2rem'}}
      activeKey={activeTab} onSelect={handleTabChange}>
        <Tab eventKey="blurb" title="Read Me">
          <BlurbTab />
        </Tab>
        <Tab eventKey="index" title="ML Index">
  <IndexDataTab
    selectedCounty={selectedCounty}
    indexPropertyType={indexPropertyType}
    onPropertyTypeChange={handleIndexPropertyTypeChange}
    onIndexLocationValuesChange={handleIndexLocationValuesChange}
  />
</Tab>

        <Tab eventKey="raw" title="Raw Data">
  <RawDataTab
    selectedCounty={selectedCounty}
    rawPropertyType={rawPropertyType}
    onPropertyTypeChange={handleRawPropertyTypeChange}
    onRawLocationValuesChange={handleRawLocationValuesChange}
  />
</Tab>
      </Tabs>
    } />
    </Col>
  </Row>
</Container>

      <AdComponent />

</>


  );
};

export default Trends;
