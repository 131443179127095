import May2024 from './raw_data/May2024';
import July2024 from './july_2024/July2024';
import HowTo from './misc/HowTo';
import Methodology from './misc/Methodology';

const BASE_URL = 'https://otta.property/blog/';

const createPost = (id, Component, link, title, image) => ({
  id,
  Component,
  link,
  title,
  image,
  ...(Component === HowTo && { props: { url: `${BASE_URL}${link}` } })
});

const Posts = [
  createPost(
    1,
    May2024,
    'may_2024_raw_data_analysis',
    'May 2024: A quick set of charts',
    'blog_images/Detached/1.png'
  ),
  createPost(
    2,
    HowTo,
    'how_to_create_machine_learning_property_indices',
    'Modernising property indices with Machine Learning',
    'blog_images/how_to/England.png'
  ),
  createPost(
    3,
    July2024,
    'july_2024',
    'Index: July 2024',
    'blog_images/july_index/All/1.png'
  ),
  createPost(
    4,
    Methodology,
    'methodology',
    'Methodology',
    'blog_images/july_index/england_years.png'
  )
];

export default Posts;