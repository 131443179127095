import { graphqlOperation } from '@aws-amplify/api-graphql';
import { getPrediction } from '../graphql/queries';
import { generateClient } from '@aws-amplify/api';

const client = generateClient();

// Simple in-memory rate limiting
const rateLimiter = {
  calls: {},
  limit: 5,
  interval: 5000,
  throttle: (key) => {
    const now = Date.now();
    if (!rateLimiter.calls[key]) {
      rateLimiter.calls[key] = [now];
      return false;
    }
    const callTimes = rateLimiter.calls[key];
    const recentCalls = callTimes.filter(time => now - time < rateLimiter.interval);
    if (recentCalls.length >= rateLimiter.limit) {
      return true;
    }
    rateLimiter.calls[key] = [...recentCalls, now];
    return false;
  }
};

const getIpAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip.toString();
  } catch (error) {
    return 'Unknown';
  }
};

export const fetchPredictionData = async (inputData) => {
  if (rateLimiter.throttle('fetchPredictionData')) {
    throw new Error("");
  }

  try {
    inputData.ip = await getIpAddress();
    const response = await client.graphql(graphqlOperation(getPrediction, { input: inputData }));
    return response.data.getPrediction;
  } catch (error) {
    throw error;
  }
};

export const fetchLocation = async (normalizedQuery) => {
  if (rateLimiter.throttle('fetchLocation')) {
    return null;
  }

  const stub = normalizedQuery.match(/[a-zA-Z]+/g)[0];
  const url = `postcode/${stub}.json`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      return null;
    }
    const locationDataArray = await response.json();
    const location = locationDataArray[normalizedQuery];
    return location || null;
  } catch (error) {
    return null;
  }
}