import React from 'react';
import { Helmet } from 'react-helmet';

const SEOComponent = ({ title, description, url, image }) => {
  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content="UK property trends, UK property market analysis, machine learning property valuation, 
      house price predictor, Land Registry UK, Energy Performance Certificate (EPC), UK house prices, UK property prices statistics, 
      property market insights, property data analytics, price per square metre UK" />

      <link rel="canonical" href={url} />

      {/* Open Graph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* Additional SEO tags */}
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Otta.property" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      
      {/* Favicon */}

      {/* Structured Data (JSON-LD) */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "WebSite",
          "url": url,
          "name": title,
          "description": description,
          "publisher": {
            "@type": "Organization",
            "name": "Otta.property",
          },
          "image": {
            "@type": "ImageObject",
            "url": image
          }
        })}
      </script>
    </Helmet>
  );
};

export default SEOComponent;
