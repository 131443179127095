import React, { useState, useCallback, useEffect } from 'react';
import { SearchForm } from '../../components/searchForm';
import { TypeDropdown } from '../../components/radioButtons';
import PriceRangeSlider from '../../components/priceRangeSlider';
import DateRangeSlider from '../../components/dateRangeSlider';
import { useMapData } from './useMapdata';
import { MapPlot } from './MapPlot';
import './Map.css';

import SEOComponent from '../../components/SEOComponent';


async function fetchLocation(normalizedQuery) {
  const stub = normalizedQuery.match(/[a-zA-Z]+/g)[0];
  const url = `postcode/${stub}.json`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      console.log('HTTP error:', response.status);
      return null;
    }
    const locationData = await response.json();
    const location = locationData[normalizedQuery];
    return location;
  } catch (error) {
    console.log('Error:', error);
    return null;
  }
}

function Map() {
  const [searchQuery, setSearchQuery] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [mapKey, setMapKey] = useState(0);
  const [radioValue, setRadioValue] = useState('All');
  const [zoom, setZoom] = useState(6.5);
  const [center, setCenter] = useState({ lat: 51.748354, lon: -1.251952 });
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { isLoading, mapData, zoomLevel, colorBarRange, fetchMapData } = useMapData(searchQuery);
  const absoluteMaxPrice = mapData.length > 0 ? Math.max(...mapData.map(d => d.price)) : 50000000;
  // const absoluteMaxPrice = 50000000;
  const absoluteMinPrice = 3000;
   // Define states
   const current_date = new Date();
   const stringMaxDate = new Date(current_date.getFullYear(), current_date.getMonth(), 0).toISOString().split('T')[0];
   
  
  const [minPrice, setMinPrice] = useState(absoluteMinPrice); // Initialize with your data's min price
  const [maxPrice, setMaxPrice] = useState(absoluteMaxPrice); // Initialize with your data's max price

  const [minDate, setMinDate] = useState('2020-01-01'); // Initialize with your data's min date
  const [maxDate, setMaxDate] = useState(stringMaxDate); // Initialize with your data's max date

  const [filteredMapData, setFilteredMapData] = useState([])
  const [mapboxStyleIndex, setMapboxStyleIndex] = useState(0); // New state to track the current style index
  const [colourScaleIndex, setColourScaleIndex] = useState(0); // New state to track the current colour scale index
  const mapboxStyles = ['open-street-map'];
  const colourScalePallets = [[  ['0.00', 'rgb(0, 0, 128)'],
['0.50', 'rgb(78, 126, 255)'],
['1.00', 'rgb(135, 206, 250)']],[
    ['0.00', 'rgb(32, 93, 152)'],
    ['0.03', 'rgb(60, 90, 157)'],
    ['0.07', 'rgb(81, 85, 162)'],
    ['0.10', 'rgb(110, 85, 167)'],
    ['0.14', 'rgb(135, 85, 173)'],
    ['0.17', 'rgb(161, 85, 176)'],
    ['0.21', 'rgb(184, 86, 165)'],
    ['0.24', 'rgb(201, 85, 149)'],
    ['0.28', 'rgb(217, 84, 137)'],
    ['0.31', 'rgb(233, 83, 124)'],
    ['0.34', 'rgb(244, 87, 111)'],
    ['0.38', 'rgb(248, 106, 95)'],
    ['0.41', 'rgb(252, 129, 84)'],
    ['0.45', 'rgb(255, 150, 71)'],
    ['0.48', 'rgb(255, 169, 59)'],
    ['0.52', 'rgb(255, 170, 50)'],
    ['0.55', 'rgb(255, 155, 47)'],
    ['0.59', 'rgb(254, 140, 43)'],
    ['0.62', 'rgb(252, 123, 41)'],
    ['0.66', 'rgb(250, 106, 37)'],
    ['0.69', 'rgb(246, 93, 36)'],
    ['0.72', 'rgb(241, 82, 37)'],
    ['0.76', 'rgb(236, 71, 38)'],
    ['0.79', 'rgb(231, 61, 38)'],
    ['0.83', 'rgb(226, 50, 39)'],
    ['0.86', 'rgb(220, 40, 39)'],
    ['0.90', 'rgb(214, 30, 40)'],
    ['0.93', 'rgb(208, 20, 42)'],
    ['0.97', 'rgb(202, 10, 44)'],
    ['1.00', 'rgb(196, 0, 49)']
],
[['0.00', 'rgb(13, 8, 135)'],
['0.10', 'rgb(65, 4, 157)'],
['0.20', 'rgb(105, 0, 168)'],
['0.30', 'rgb(142, 12, 164)'],
['0.40', 'rgb(174, 40, 146)'],
['0.50', 'rgb(201, 68, 122)'],
['0.60', 'rgb(222, 97, 100)'],
['0.70', 'rgb(240, 127, 79)'],
['0.80', 'rgb(251, 161, 57)'],
['0.90', 'rgb(253, 198, 39)'],
['1.00', 'rgb(243, 240, 39)']]
];

  const currentMapboxStyle = mapboxStyles[mapboxStyleIndex];
  const currentColourScale = colourScalePallets[colourScaleIndex];

  const changeMapboxStyle = () => {
    // add 1 to the current style index and the colour scale index
    const newIndex = (mapboxStyleIndex + 1) % mapboxStyles.length;
    const newColourScaleIndex = (colourScaleIndex + 1) % colourScalePallets.length;

    // Update the style index and the colour scale index
    setMapboxStyleIndex(newIndex);
    setColourScaleIndex(newColourScaleIndex);
  }






  useEffect(() => {
    const minDateTimestamp = new Date(minDate).getTime();
    const maxDateTimestamp = new Date(maxDate).getTime();
    const filteredData = mapData.filter(item => item.price >= minPrice && item.price <= maxPrice && item.date >= minDateTimestamp && item.date <= maxDateTimestamp);

    setFilteredMapData(filteredData);
  }, [mapData, minPrice, maxPrice, minDate, maxDate]);
  
  



  const updateMapView = useCallback(({ zoom, center }) => {
    setZoom(zoom.toFixed(3));
    setCenter(center);
    // set the max price to the max price in the current view
    const maxPriceInView = Math.max(...mapData.map(d => d.price));
    setMaxPrice(maxPriceInView);

    // set the min price to the min price in the current view
    const minPriceInView = Math.min(...mapData.map(d => d.price));
    setMinPrice(minPriceInView);
  }, [mapData]);


  const handleSearch = useCallback(async () => {
    setIsButtonDisabled(true);
    try {
      const normalizedQuery = searchQuery.replace(/\s+/g, "").toUpperCase();
      const location = await fetchLocation(normalizedQuery);

      if (location) {
        setMapKey((prevKey) => prevKey + 1);
        setCenter({ lat: location.latitude, lon: location.longitude });
        setZoom(14);
      } else {
        console.error('Location not found');
      }
    } catch (error) {
      console.error('Failed to search:', error);
    } finally {
      setIsButtonDisabled(false);
    }
  }, [searchQuery]);

  const handleRadioChange = useCallback((event) => {
    setRadioValue(event.target.value);
  }, []);

  useEffect(() => {
    let debounceTimer;

    if (!radioValue || !zoomLevel) {
      return;
    }

    debounceTimer = setTimeout(() => {
      fetchMapData(center.lon, center.lat, zoom, radioValue);
    }, 100);

    return () => clearTimeout(debounceTimer);
  }, [center, radioValue, zoom, zoomLevel, fetchMapData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const toSliderScale = (price) => {
    // Assuming price range is 1 to 50,000,000
    const logMin = Math.log(absoluteMinPrice); // Logarithm of 1, which is 0
    const logMax = Math.log(absoluteMaxPrice); // Logarithm of the maximum price
    const logPrice = Math.log(price); // Logarithm of the current price
    // Normalize the logarithmic value to between 0 and 100
    const sliderValue = ((logPrice - logMin) / (logMax - logMin)) * 1000;
    return sliderValue;
}

// Converts a normalized logarithmic scale value back to the price
const fromSliderScale = (sliderValue) => {
    const logMin = Math.log(absoluteMinPrice);
    const logMax = Math.log(absoluteMaxPrice);
    // Reverse the normalization to get the logarithmic value
    const logPrice = ((sliderValue / 1000) * (logMax - logMin)) + logMin;
    // Convert the logarithmic value back to the price
    const price = Math.exp(logPrice);
    return price;
}

const toDateSliderScale = (date) => {
  const minDate = new Date('2020-01-01').getTime();
  const maxDate = new Date(stringMaxDate).getTime();
  const dateTimestamp = new Date(date).getTime();
  const sliderValue = ((dateTimestamp - minDate) / (maxDate - minDate)) * 1000;
  return sliderValue;
}

const fromDateSliderScale = (sliderValue) => {
  const minDate = new Date('2020-01-01').getTime();
  const maxDate = new Date(stringMaxDate).getTime();
  const dateTimestamp = ((sliderValue / 1000) * (maxDate - minDate)) + minDate;
  return new Date(dateTimestamp).toISOString().split('T')[0];
}




  const handleMinPriceChange = (sliderValue) => {
    const newMinPrice = fromSliderScale(sliderValue); // Convert slider value back to actual price value if necessary
    setMinPrice(newMinPrice); // Assuming you have a state [minPrice, setMinPrice] for tracking minimum price
    // Optionally trigger filtering logic here or elsewhere depending on application structure
  };

  // Function to handle changes in the maximum price from the RangeSlider
  const handleMaxPriceChange = (sliderValue) => {
    const newMaxPrice = fromSliderScale(sliderValue); // Convert slider value back to actual price value if necessary
    setMaxPrice(newMaxPrice); // Assuming you have a state [maxPrice, setMaxPrice] for tracking maximum price
    // Optionally trigger filtering logic here or elsewhere depending on application structure
  };

  const handleMinDateChange = (sliderValue) => {
    const newMinDate = fromDateSliderScale(sliderValue); // Convert slider value back to actual price value if necessary
    setMinDate(newMinDate); // Assuming you have a state [minPrice, setMinPrice] for tracking minimum price
  };


  const handleMaxDateChange = (sliderValue) => {
    const newMaxDate = fromDateSliderScale(sliderValue); // Convert slider value back to actual price value if necessary
    setMaxDate(newMaxDate); // Assuming you have a state [maxPrice, setMaxPrice] for tracking maximum price
  };
  
  const toggleVisibility = () => {
    setIsCollapsed(!isCollapsed);
  };

  

  return (
    <>

    <head>
      <style>
        {`
          html, body {
            height: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;

          }
        `}
      </style>
    </head>

    <body>

      <SEOComponent title="Interactive Property Map" description="Land Registry Data Plotted on an interactive map" url="https://otta.property/map" image="https://otta.property/trends2.webp" />

    
    <div className="parent-container">
  <div className="options-container">
    <button className="toggle-button" onClick={toggleVisibility}>
      {isCollapsed ? 'Show Options' : 'Hide Options'}
    </button>
    {/* art pallette  emoji in text */}
    <button className= 'map-style-button' onClick={changeMapboxStyle}>  🎨 </button>
    <div className={`search-container ${isCollapsed ? 'hidden' : 'visible'}`}>
      <SearchForm
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onSearch={handleSearch}
        isButtonDisabled={isButtonDisabled}
      />
      <TypeDropdown radioValue={radioValue} onChange={handleRadioChange} />
      <PriceRangeSlider
        min_label={`£${(Math.round(minPrice / 10) * 10).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
        max_label={`£${(Math.round(maxPrice / 10) * 10).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
        min={1}
        max={1000}
        value1={toSliderScale(minPrice)}
        value2={toSliderScale(maxPrice)}
        onChange1={(value) => handleMinPriceChange(value)}
        onChange2={(value) => handleMaxPriceChange(value)}
        mapData={mapData}
        setFilteredData={setFilteredMapData}
        isCollapsed={isCollapsed}
      />
      <DateRangeSlider
        min_label= {minDate}
        max_label= {maxDate}
        min={1}
        max={1000}
        value1={toDateSliderScale(minDate)}
        value2={toDateSliderScale(maxDate)}
        onChange1={(value) => handleMinDateChange(value)}
        onChange2={(value) => handleMaxDateChange(value)}
        mapData={mapData}
        setFilteredData={setFilteredMapData}
        isCollapsed={isCollapsed}
      />
     
    </div>
    </div>
     

    <div className="map-container" >
      <MapPlot
        key={mapKey}
        mapData={filteredMapData}
        mapCenter={center}
        zoomLevel={zoom}
        colorBarRange={colorBarRange}
        updateMapView={updateMapView}
        mapboxStyle={currentMapboxStyle}
        colorscalePalette={currentColourScale}
      />
    </div>
  </div>
  </body>
  </>
  
  );
}

export default Map;
