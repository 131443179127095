import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname + location.search;
    const title = document.title;

    ReactGA.set({ page, title });
    ReactGA.pageview(page);
  }, [location]);
};

export default usePageTracking;
