import React, { useRef, useEffect, useState, useCallback } from 'react';
import L from 'leaflet';
import * as d3 from 'd3';
import 'leaflet/dist/leaflet.css';
import pako from 'pako';

const ZOOM_TO_SAMPLE = {
    6: { interval: 6 },
    7: { interval: 3 },
    8: { interval: 1.5 },
    9: { interval: 0.75 },
    10: { interval: 0.375 },
    11: { interval: 0.1875 },
    12: { interval: 0.09375 },
    13: { interval: 0.04 },
    14: { interval: 0.02 },
};

const VoronoiMap = () => {
    const mapRef = useRef(null);
    const mapInstanceRef = useRef(null);
    const svgRef = useRef(null);
    const gRef = useRef(null);
    const tooltipRef = useRef(null);
    const isFetchingRef = useRef(false);
    const [data, setData] = useState([]);
    const legendRef = useRef(null);
    const [maxPrice, setMaxPrice] = useState(20000);
    const HOVER_RADIUS = 50;

    const calculateTileCoordinates = useCallback((bounds, sampleInterval) => {
        const coordinates = new Set();
        
        const margin = sampleInterval * 2;
        
        const minLat = Math.floor((bounds.getSouth() - margin) / sampleInterval) * sampleInterval;
        const maxLat = Math.ceil((bounds.getNorth() + margin) / sampleInterval) * sampleInterval;
        const minLng = Math.floor((bounds.getWest() - margin) / sampleInterval) * sampleInterval;
        const maxLng = Math.ceil((bounds.getEast() + margin) / sampleInterval) * sampleInterval;
    
        for (let lat = minLat; lat <= maxLat; lat += sampleInterval) {
            for (let lng = minLng; lng <= maxLng; lng += sampleInterval) {
                coordinates.add({
                    lat: parseFloat(lat.toFixed(5)),
                    lng: parseFloat(lng.toFixed(5))
                });
            }
        }
    
        return Array.from(coordinates);
    }, []);

    const generateTileUrl = useCallback((lat, lng, zoom) => {
        return `/sqm_panel/All/${zoom}_${lat.toFixed(5)}_${lng.toFixed(5)}.json.gz`;
    }, []);

    const fetchTileData = useCallback(async (bounds, zoom) => {
        if (isFetchingRef.current) return;
        isFetchingRef.current = true;

        try {
            const roundedZoom = Math.max(6, Math.min(14, Math.floor(zoom)));
            const sampleInterval = ZOOM_TO_SAMPLE[roundedZoom].interval;
            
            const tileCoordinates = calculateTileCoordinates(bounds, sampleInterval);
            
            const urls = tileCoordinates.map(coord => 
                generateTileUrl(coord.lat, coord.lng, roundedZoom)
            );

            const responses = await Promise.all(
                urls.map(url => 
                    fetch(url)
                        .then(async response => {
                            if (!response.ok) return null;
                            try {
                                const buffer = await response.arrayBuffer();
                                const decompressed = pako.inflate(new Uint8Array(buffer), { to: 'string' });
                                return decompressed ? JSON.parse(decompressed) : null;
                            } catch (error) {
                                console.warn('Error processing tile:', error);
                                return null;
                            }
                        })
                        .catch(() => null)
                )
            );

            const newData = responses
                .filter(json => json !== null)
                .flat()
                .filter(item => item !== null && 
                    bounds.contains(L.latLng(item.lat, item.lng)));

            const dataMax = d3.max(newData, d => d.price_per_sqm);
            setMaxPrice(Math.min(Math.ceil((Math.max(10000, dataMax || 20000)) / 1000) * 1000, 20000));
            setData(newData);
        } finally {
            isFetchingRef.current = false;
        }
    }, [calculateTileCoordinates, generateTileUrl]);

    useEffect(() => {
        if (!mapRef.current || mapInstanceRef.current) return;

        mapInstanceRef.current = L.map(mapRef.current, { zoomControl: false }).setView([51.505, -0.09], 10);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors',
            crossOrigin: true,
            preconnect: true,  // Add preconnect
        }).addTo(mapInstanceRef.current);

        svgRef.current = d3.select(mapInstanceRef.current.getPanes().overlayPane)
            .append("svg")
            .style("position", "absolute")
            .style("pointer-events", "none");
        
        gRef.current = svgRef.current.append("g")
            .attr("class", "leaflet-zoom-hide");

        tooltipRef.current = d3.select(mapRef.current)
            .append("div")
            .attr("class", "tooltip")
            .style("position", "absolute")
            .style("visibility", "hidden")
            .style("background-color", "white")
            .style("padding", "10px")
            .style("border-radius", "5px")
            .style("box-shadow", "0 0 10px rgba(0,0,0,0.1)")
            .style("z-index", "1000")
            .style("pointer-events", "none");

        legendRef.current = d3.select(mapRef.current)
            .append("div")
            .style("position", "absolute")
            .style("top", "20px")
            .style("right", "20px")
            .style("background", "white")
            .style("padding", "10px")
            .style("border-radius", "5px")
            .style("box-shadow", "0 0 10px rgba(0,0,0,0.1)")
            .style("z-index", "1000");

        const bounds = mapInstanceRef.current.getBounds();
        fetchTileData(bounds, 10);

        return () => {
            if (mapInstanceRef.current) {
                mapInstanceRef.current.remove();
                mapInstanceRef.current = null;
            }
            if (legendRef.current) {
                legendRef.current.remove();
            }
            if (tooltipRef.current) {
                tooltipRef.current.remove();
            }
        };
    }, [fetchTileData]);

    useEffect(() => {
        if (!legendRef.current) return;
    
        legendRef.current.selectAll("*").remove();
    
        const legendSvg = legendRef.current
            .append("svg")
            .attr("width", 300)
            .attr("height", 80);
    
        const gradientScale = d3.scaleLinear()
            .domain([0, maxPrice])
            .range([0, 260]);
    
        const gradient = legendSvg
            .append("defs")
            .append("linearGradient")
            .attr("id", "price-gradient")
            .attr("x1", "0%")
            .attr("x2", "100%")
            .attr("y1", "0%")
            .attr("y2", "0%");
    
        const colorScale = d3.scaleSequential()
            .domain([0, maxPrice])
            .interpolator(d3.interpolateRdYlBu);
    
        const stops = [0, 0.25, 0.5, 0.75, 1];
        stops.forEach(stop => {
            gradient.append("stop")
                .attr("offset", `${stop * 100}%`)
                .attr("stop-color", colorScale(maxPrice * (1 - stop)));
        });
    
        legendSvg.append("rect")
            .attr("x", 20)
            .attr("y", 15)
            .attr("width", 260)
            .attr("height", 20)
            .style("fill", "url(#price-gradient)");
    
        const axis = d3.axisBottom(gradientScale)
            .tickFormat(d => `£${(d/1000).toFixed(0)}k`)
            .ticks(5);
    
        legendSvg.append("g")
            .attr("transform", "translate(20, 35)")
            .call(axis)
            .style("font-size", "12px");
    
        legendSvg.append("text")
            .attr("x", 150)
            .attr("y", 70)
            .style("font-size", "12px")
            .style("text-anchor", "middle")
            .text("Price per Square Metre");
    }, [maxPrice]);

    useEffect(() => {
        if (!mapInstanceRef.current) return;

        const map = mapInstanceRef.current;

        function handleMoveStart() {
            if (svgRef.current) {
                svgRef.current.style.display = 'none';
            }
        }

        function handleMoveEnd() {
            if (svgRef.current) {
                svgRef.current.style.display = '';
            }
            const bounds = map.getBounds();
            const zoom = map.getZoom();
            fetchTileData(bounds, zoom);
        }

        map.on('movestart zoomstart', handleMoveStart);
        map.on('moveend zoomend', handleMoveEnd);

        return () => {
            map.off('movestart zoomstart', handleMoveStart);
            map.off('moveend zoomend', handleMoveEnd);
        };
    }, [fetchTileData]);

    useEffect(() => {
        if (!mapInstanceRef.current || !svgRef.current || !gRef.current || !data.length) return;

        const map = mapInstanceRef.current;

        function updateVoronoi() {
            const bounds = map.getBounds();
            const topLeft = map.latLngToLayerPoint(bounds.getNorthWest());
            const bottomRight = map.latLngToLayerPoint(bounds.getSouthEast());
            const width = bottomRight.x - topLeft.x;
            const height = bottomRight.y - topLeft.y;

            svgRef.current
                .attr("width", width)
                .attr("height", height)
                .style("left", topLeft.x + "px")
                .style("top", topLeft.y + "px")
                .style("pointer-events", "none");

            gRef.current.attr("transform", `translate(${-topLeft.x},${-topLeft.y})`);

            const points = data.map(d => {
                const point = map.latLngToLayerPoint([d.lat, d.lng]);
                return {
                    x: point.x,
                    y: point.y,
                    price: d.price_per_sqm,
                    original: d
                };
            });

            const voronoi = d3.Delaunay
                .from(points, d => d.x, d => d.y)
                .voronoi([topLeft.x - width/2, topLeft.y - height/2, 
                         bottomRight.x + width/2, bottomRight.y + height/2]);

            function getDistance(p1, p2) {
                const dx = p1.x - p2.x;
                const dy = p1.y - p2.y;
                return Math.sqrt(dx * dx + dy * dy);
            }

            function findCellsWithinRadius(centerPoint, radius) {
                return points.filter(p => getDistance(p, centerPoint) <= radius);
            }

            function calculateMeanPrice(cells) {
                if (!cells.length) return 0;
                const sum = cells.reduce((acc, cell) => acc + cell.price, 0);
                return sum / cells.length;
            }

            function updateLabelsPosition(event, d) {
                // Get container position
                const containerPos = map.getContainer().getBoundingClientRect();
                // Get current map pane position
                const mapPane = map.getPanes().mapPane;
                const mapPanePos = mapPane.getBoundingClientRect();
                
                // Calculate correct position relative to the map container and accounting for pan
                const x = event.clientX - containerPos.left - mapPanePos.left;
                const y = event.clientY - containerPos.top - mapPanePos.top;

                const nearbyCells = findCellsWithinRadius(d, HOVER_RADIUS);
                const meanPrice = calculateMeanPrice(nearbyCells);

                // Remove existing labels
                gRef.current.selectAll(".price-label").remove();

                // Add labels with corrected positions
                gRef.current.append("text")
                    .attr("class", "price-label")
                    .attr("x", x)
                    .attr("y", y + 30)
                    .attr("text-anchor", "middle")
                    .attr("fill", "#000")
                    .attr("font-weight", "bold")
                    .attr("font-size", "14px")
                    .attr("paint-order", "stroke")
                    .attr("stroke", "#fff")
                    .attr("stroke-width", "3px")
                    .text(`Area average: ${new Intl.NumberFormat('en-GB', { 
                        style: 'currency', 
                        currency: 'GBP',
                        maximumFractionDigits: 0
                    }).format(Math.round(meanPrice))}`);

                gRef.current.append("text")
                    .attr("class", "price-label")
                    .attr("x", x)
                    .attr("y", y + 55)
                    .attr("text-anchor", "middle")
                    .attr("fill", "#000")
                    .attr("font-weight", "bold")
                    .attr("font-size", "14px")
                    .attr("paint-order", "stroke")
                    .attr("stroke", "#fff")
                    .attr("stroke-width", "3px")
                    .text(`Selected price: ${new Intl.NumberFormat('en-GB', { 
                        style: 'currency', 
                        currency: 'GBP',
                        maximumFractionDigits: 0
                    }).format(d.price)}`);
            }

            const cells = gRef.current.selectAll("path.cell")
                .data(points, d => `${d.original.lat}-${d.original.lng}-${d.original.price_per_sqm}`);

            cells.exit().remove();

            function resetAllCells() {
                cells.merge(cells)
                    .attr("opacity", 0.3)
                    .attr("stroke", null)
                    .attr("stroke-width", null);
                gRef.current.selectAll(".price-label").remove();
            }

            const cellsUpdate = cells.enter()
                .append("path")
                .attr("class", "cell")
                .style("pointer-events", "auto")
                .merge(cells)
                .attr("d", (d, i) => voronoi.renderCell(i))
                .attr("fill", d => d3.interpolateRdYlBu(1 - d.price / maxPrice))
                .attr("opacity", 0.3)
                .style("cursor", "pointer")
                .on("mouseover", function(event, d) {
                    cellsUpdate
                        .attr("opacity", p => getDistance(p, d) <= HOVER_RADIUS ? 1 : 0.3)
                        .attr("stroke", p => getDistance(p, d) <= HOVER_RADIUS ? "#fff" : null)
                        .attr("stroke-width", p => getDistance(p, d) <= HOVER_RADIUS ? "1px" : null);
                    
                    updateLabelsPosition(event, d);
                })
                .on("mousemove", function(event, d) {
                    updateLabelsPosition(event, d);
                })
                .on("mouseleave", resetAllCells);
        }

        updateVoronoi();
        map.on("moveend", updateVoronoi);
        map.on("zoomend", updateVoronoi);

        return () => {
            map.off("moveend", updateVoronoi);
            map.off("zoomend", updateVoronoi);
        };
    }, [data, maxPrice]);

    return (
        <div style={{ position: 'relative', touchAction: 'none' }}>
            <div ref={mapRef} style={{ width: '100%', height: '100vh', overflow: 'hidden' }} />
        </div>
    );
};

export default VoronoiMap;
                        