import React from 'react';




export const HouseTypeSelector = ({ handleInputChange, inputData }) => {
    return (
        <>

                <label htmlFor="house_type">House Type:</label>
                <select 
                    name="house_type" 
                    id="house_type" 
                    onChange={handleInputChange} 
                    value={inputData.house_type}
                    className="form-select">
                    <option value="Detached">Detached</option>
                    <option value="Semi-detached">Semi-Detached</option>
                    <option value="Terraced">Terraced</option>
                    <option value="Flat">Flat</option>
                </select>
                </>
    );
};


export const FloorAreaSelector = ({ handleInputChange, inputData }) => {
    return (
    <>
    <div style={{ position: 'relative', width: '100%' }}>
        <input 
            type="range"
            id="TOTAL_FLOOR_AREA"
            name="TOTAL_FLOOR_AREA"
            min="10"
            max="350"
            value={inputData.TOTAL_FLOOR_AREA}
            onChange={handleInputChange}
            style={{ transform: 'translateX(-50%)' }} // Shift the slider to the left by half its width
            className="form-range"
        />
        <br />
    </div>
    </>
    );
}



export const FreeholdSelector = ({ handleInputChange, inputData }) => {
    return (

        <>
        <label htmlFor="freehold_leasehold">Freehold or Leasehold:</label>
        <select name="freehold_leasehold" id="freehold_leasehold" onChange={handleInputChange} value={inputData.freehold_leasehold} className="form-select">

            <option value="F">Freehold</option>
            <option value="L">Leasehold</option>
            {/* init as the inputData */}

        </select>
        </>
    );
}

export const OldOrNewSelector = ({ handleInputChange, inputData }) => {
    return (

        <>
        <label htmlFor="old_or_new">Old or New:</label>
        <select name="new" id="old_or_new" onChange={handleInputChange} value={inputData.new} className="form-select">

            <option value="N">Old</option>
            <option value="Y">New</option>
            {/* init as the inputData */}

        </select>
        </>
    );
}

