import React from 'react';

const PropertyAreaSelect = ({ propertyArea, onSelect }) => (
    <div style={{ gap: '0.8em', display: 'flex', justifyContent: 'center', fontSize: '0.8em' }}>
      {propertyArea.map((area) => (
        <label key={area}>
          <input type="radio" name="propertyArea" value={area} onChange={e => onSelect(e.target.value)} />
          {area === 'All' ? 'All' : area === '230' ? `>230m²` : `${area}m²`}
  
  
          
        </label>
      ))}
    </div>
  );

export default PropertyAreaSelect;