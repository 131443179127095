import React from 'react';
import HexbinMap from './HexBinMap';
import './SqmMap.css';
import SEOComponent from '../../components/SEOComponent';

function SqmMap() {
  return (
    <>

      <SEOComponent
        title={'Otta.property - Property prices per square meter in the UK'}
        description={'Property prices per square meter in the UK'}
        url={'https://otta.property/map/sqm'}
        image={'https://otta.property/trends2.webp'}
      />
    <div className="sqmparent-container">
      <div className="sqmmap-container">
        <HexbinMap />
      </div>
    </div>
    </>
  );
}

export default SqmMap;