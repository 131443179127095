import React from 'react';
import './PredictionSearchForm.css';

export function PredictionSearchForm({ searchQuery, setSearchQuery }) {
  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFocus = (e) => {
    e.target.focus();
  }

  return (
      <div className='prediction-search-form'>
        <label htmlFor='prediction-postcode-input-box'>Enter postcode:</label>
        <input
          type="text"
          placeholder="Or drag the map"
          value={searchQuery ? searchQuery.toUpperCase() : ''}
          onChange={handleChange}
          onTouchStart={handleFocus} // Ensure focus on touch devices
          id='prediction-postcode-input-box'
        />
      </div>
  );
}
