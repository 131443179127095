import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Container, Col, Row, Accordion, Table, Button } from 'react-bootstrap';
import { CallToJoinPredictor } from '../../components/CallsToAction';
import { MapComponent } from '../PPMap/MapComponent';
import { BuyButton } from '../../components/BuyButtons';
import { PredictionSearchForm } from '../../components/PredictionSearchForm';
import SiteCard from '../../components/SiteCard';
import SEOComponent from '../../components/SEOComponent';
import PredictionChart from '../../components/PredictionChart';
import { HouseTypeSelector, FloorAreaSelector } from '../../components/PredictorSelectors';
import fetchOrUpdateUserStatus from '../../components/Membership';
import { calculateMean, calculateMedian, calculateModeRange } from './stats';
import { fetchPredictionData, fetchLocation } from '../../services/Api';
import './Predictor.css';

function Predictor() {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    const [mapKey, setMapKey] = useState(0);
    const { user } = useAuthenticator();
    const [predictionData, setPredictionData] = useState(null);
    const [predictionMean, setPredictionMean] = useState(null);
    const [predictionMedian, setPredictionMedian] = useState(null);
    const [predictionMode, setPredictionMode] = useState(null);
    const [error, setError] = useState('');
    const [predictionHistory, setPredictionHistory] = useState([]);
    const [inputData, setInputData] = useState({
        email: '',
        latitude: 51.748354,
        longitude: -1.251952,
        TOTAL_FLOOR_AREA: 100.0,
        house_type: 'Semi-detached',
    });
    const [center, setCenter] = useState({ lat: 51.748354, lon: -1.251952 });
    const [zoom, setZoom] = useState(6.5);
    const [status, setStatus] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const lastUserIdRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showBuyButton, setShowBuyButton] = useState(false);

    const handleSearch = useCallback(async () => {
        setIsButtonDisabled(true);
        let result = null;
    
        try {
            if (!searchQuery) {
                console.info('No search query provided, falling back to default');
                return null;
            }

            const normalizedQuery = searchQuery.replace(/\s+/g, "").toUpperCase();
            const location = await fetchLocation(normalizedQuery);         
    
            if (location) {
                result = { latitude: location.latitude, longitude: location.longitude};
            } else {
                console.info('Location not found, falling back to default');
            }
        } catch (error) {
            console.error('Failed to search:', error);
        } finally {
            setIsButtonDisabled(false);
        }

        return result;
    }, [searchQuery]);

    const updateMapView = useCallback(({ zoom, center }) => {
        setZoom(zoom.toFixed(3));
        setCenter(center);
        setInputData(prevState => ({
            ...prevState,
            latitude: center.lat,
            longitude: center.lon
        }));
    }, []);

    const downloadHistory = () => {
        // Early return if not subscribed
        if (!status) return;
    
        const headers = ['Timestamp', 'Latitude', 'Longitude', 'Floor Area (m²)', 'House Type', 'Mean Price (£)', 'Median Price (£)', 'Likely Range (£)'];
        const csvContent = [
            headers.join(','),
            ...predictionHistory.map(entry => [
                entry.timestamp,
                entry.latitude,
                entry.longitude,
                entry.floorArea,
                entry.houseType,
                entry.mean,
                entry.median,
                // as the likely range is a string, we need to wrap it in quotes to avoid issues with commas
                `"${entry.modeRange}"`

            ].join(','))
        ].join('\n');
        
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `prediction_history_${new Date().toISOString()}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    const copyToClipboard = () => {
        // Early return if not subscribed
        if (!status) return;
    
        const headers = ['Timestamp', 'Latitude', 'Longitude', 'Floor Area (m²)', 'House Type', 'Mean Price (£)', 'Median Price (£)', 'Likely Range (£)'];
        const tsvContent = [
            headers.join('\t'),
            ...predictionHistory.map(entry => [
                entry.timestamp,
                entry.latitude,
                entry.longitude,
                entry.floorArea,
                entry.houseType,
                entry.mean,
                entry.median,
                entry.modeRange

            ].join('\t'))
        ].join('\n');
        
        navigator.clipboard.writeText(tsvContent)
            .then(() => alert('Data copied to clipboard!'))
            .catch(err => console.error('Failed to copy data:', err));
    };
    useEffect(() => {
        const processUserStatus = async () => {
            if (user && status === null && lastUserIdRef.current !== user.userId) {
                try {
                    const result = await fetchOrUpdateUserStatus(user);
                    console.log('User status:', result);
                    if (result.stripe && result.stripe.stripe_sub_status === 'active') {
                        setStatus(true);

                    }
                    
  
                    if (result.email) {
                        setUserEmail(result.email);
                    }
                    lastUserIdRef.current = user.userId;
                } catch (error) {
                    console.error("Processing error:", error);
                }
            }
        };
        processUserStatus();
    }, [user, status]);
    
    useEffect(() => {
        if (user) {
            setInputData(prevState => ({
                ...prevState,
                email: user.signInDetails.loginId
            }));
        }
    }, [user]);

    const handleGetPrediction = async () => {
        setIsLoading(true);
        setIsButtonDisabled(true);
        setShowBuyButton(false);
        try {
            const location = await handleSearch();

            while (isButtonDisabled) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
            
            let userInputData = inputData;
            if (location) {
                setMapKey(prevKey => prevKey + 1);
                setCenter({ lat: location.latitude, lon: location.longitude });
                setZoom(14);   
                setSearchQuery(null);
                userInputData = {
                    ...inputData,
                    latitude: location.latitude,
                    longitude: location.longitude
                };
            }

            const data = await fetchPredictionData(userInputData);
            
            if (!data || !data.predictions || data.predictions.length === 0) {
                setPredictionData(null);
                setShowBuyButton(true);
                return;
            }

            const values = data.predictions.map(item => {
                if (typeof item.value === 'undefined' || item.value === null) {
                    console.warn('Invalid prediction value:', item);
                    return 0;
                }
                return item.value;
            });

            const modeRange = calculateModeRange(values);
            const mean = calculateMean(values);
            const median = calculateMedian(values);

            setPredictionData(data.predictions);
            setPredictionMean(mean !== null ? mean : 0);
            setPredictionMedian(median !== null ? median : 0);
            setPredictionMode(modeRange !== null ? modeRange : 'N/A');

            setPredictionHistory(prev => [...prev, {
                timestamp: new Date().toISOString().replace(/\.\d{3}Z$/, 'Z'),  // Format: yyyy-mm-ddTHH:MM:SSZ
                // round to 3 decimal places
                latitude: Math.round(userInputData.latitude * 1000) / 1000,
                longitude: Math.round(userInputData.longitude * 1000) / 1000,
                floorArea: userInputData.TOTAL_FLOOR_AREA,
                houseType: userInputData.house_type,
                mean: mean,
                median: median,
                modeRange: modeRange
            }]);
            

            setError('');
        } catch (err) {
            console.error('Error in handleGetPrediction:', err);
            setError(`Failed to fetch prediction: ${err.message}. Please try again.`);
        } finally {
            setIsButtonDisabled(false);
            setIsLoading(false);
        }
    };
        
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputData(prevState => ({ ...prevState, [name]: parseFloat(value) || value }));
    };

    if (!user) {
        return <CallToJoinPredictor />;
    }

    const grayedOutStyle = {
        opacity: 0.5,
        pointerEvents: 'none',
        fontSize: '0.8rem',
        position: 'relative'
    };

    const noSelectStyle = {
        userSelect: 'none',
        fontSize: '0.8rem',
        cursor: 'not-allowed'
    };

    const canSelectStyle = {
        userSelect: 'auto',
        fontSize: '0.8rem',
        cursor: 'auto'
    };
    

    const description = () => {
        return(
            <Accordion style={{ marginTop: '1rem' }}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>How does this work?</Accordion.Header>
                    <Accordion.Body>
                        <h1 style={{fontSize: '1.5rem'}}>Use Machine Learning to predict house prices</h1>
                        <hr />
                        <p>
                            Use the map to select a location, then input the total floor area and house type. These details help create a more accurate prediction.
                        </p>
                        <p>
                            Clicking 'Get Prediction', a request is sent to a group of 100 machine learning models with the inputs as defined above.
                            These models have been trained on the latest available data from across England and Wales.
                            Each model has been trained slightly differently and on different training data, and their predictions will vary.
                        </p>
                        <p>
                            We then look at these predictions, calculating the average (mean), middle value (median), and most common price range in which the estimates fall.
                            This gives us a comprehensive view of the likely property value and also some insight into how confident we should be in the prediction.
                        </p>
                        <p>
                            The spread of predictions is informative.
                            A narrow spread, where most predictions are close together indicates agreement among the models. This suggests a high level of confidence in the prediction.
                            A wider spread indicates more uncertainty, which is most likely due to limited data or property features in the training data which are not captured
                            in the input (e.g. condition of the property, garden size, etc).
                        </p>
                        <p style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                            *By using this tool, you understand that Inorite Ltd does not guarantee any accuracy and shall not be liable for any loss or damage whatsoever arising 
                            from the use of this tool. The estimates presented here are for illustrative purposes only and whatever the user decides to do with the information is at their own risk  😊!
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    };

    return (
        <>
            <SEOComponent 
                title="Machine Learning Property Price Predictor" 
                description="Machine Learning Property Price Predictor"
                url="https://otta.property/machine-learning-property-price-predictor" 
                image="https://otta.property/trends2.webp" 
            />
            
            {status && <p>{status.message}</p>}   
            <Container fluid className="trends-container">
                <Row style={{ width: '100%', margin: '0', backgroundColor: 'none', padding: '0'}}>
                    <Col md={5} xs={12} className="predictormap-container">
                        <MapComponent 
                            key={mapKey}
                            mapCenter={center}
                            zoomLevel={zoom}
                            updateMapView={updateMapView}
                        />
                    </Col>
                    <Col md={7} xs={12} className='predictions-column'>
                        <SiteCard header={'HOUSE PRICE PREDICTOR'}  content={  
                            <>
                                {description()}
                                <Row fluid style={{ width: '100%', margin: '0', backgroundColor: 'none', padding: '0'}}>
                                    <Col md={4} xs={12}>
                                        <Row style={{ marginTop: '1rem' }}>
                                            <Col>
                                                <form onSubmit={async (e) => {
                                                    e.preventDefault();
                                                    await handleGetPrediction();
                                                }}>
                                                    <Container style={{ border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', padding: '1rem' }}>
                                                        <Row style={{ marginBottom: '1rem' }}>
                                                            <Col style={{ marginBottom: '1rem', textAlign: 'center' }}>
                                                                <PredictionSearchForm searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginBottom: '1rem', textAlign: 'center' }}>
                                                            <Col style={{ marginBottom: '1rem', textAlign: 'center' }}>
                                                                <label htmlFor="TOTAL_FLOOR_AREA">Total Floor Area: {inputData.TOTAL_FLOOR_AREA} m²</label>
                                                                <br />
                                                                <FloorAreaSelector handleInputChange={handleInputChange} inputData={inputData} />
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginBottom: '1rem' }}>
                                                            <Col style={{ textAlign: 'center' }}>
                                                                <HouseTypeSelector handleInputChange={handleInputChange} inputData={inputData} />
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                    <br />
                                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
                                                        <button type="submit" className="otta-button" disabled={isButtonDisabled}>
                                                            {isLoading ? 'Loading...' : 'Get Prediction'}
                                                        </button>
                                                    </div>
                                                    {predictionData && (
                                                        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                                                            <Table striped bordered hover>
                                                                <thead>
                                                                <tr>
                                                                    <th>Metric</th>
                                                                    <th>Price</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td>Mean</td>
                                                                    <td>£{predictionMean.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Median</td>
                                                                    
                                                                    <td>£{predictionMedian.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Likely Range</td>
                                                                    <td>{predictionMode}</td>
                                                                </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    )}
                                                </form>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={8} xs={12}>
                                        {!predictionData && !showBuyButton && (
                                            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                                                <h3>Press the button to get a prediction</h3>
                                                <hr />
                                                <h4>This tool is accurate for typical properties, it <b>will not</b> be accurate for unique properties with unusual features!</h4>
                                            </div>
                                        )}
                                        {predictionData && (
                                            <>
                                                <PredictionChart data={predictionData} />
                                                
                                            </>
                                        )}
                                        {showBuyButton && (
                                            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                                                <h3>Subscribe for unlimited access</h3>
                                                <BuyButton userEmail={userEmail} />
                                            </div>
                                        )}
                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                    </Col>
                                    <Col md={12} xs={12}>
                                    <div className="mt-4" style={status? canSelectStyle: grayedOutStyle}>
                                                    <h4>Prediction History</h4>
                                                    {status?  null : <p>Subscribe to interact with this feature</p>}
                                                    <div className="table-responsive" style={status? canSelectStyle : grayedOutStyle}>
                                                        <Table striped bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Timestamp</th>
                                                                    <th>Latitude</th>
                                                                    <th>Longitude</th>
                                                                    <th>Floor Area (m²)</th>
                                                                    <th>House Type</th>
                                                                    <th>Mean Price (£)</th>
                                                                    <th>Median Price (£)</th>
                                                                    <th>Likely Range (£)</th>
                                                                </tr>
                                                            </thead>
                                                            
                                                            <tbody style={!status? noSelectStyle : canSelectStyle}>
                                                                {predictionHistory.map((entry, index) => (
                                                                    <tr key={index}>
                                                                        <td>{entry.timestamp}</td>
                                                                        <td>{entry.latitude}</td>
                                                                        <td>{entry.longitude}</td>
                                                                        <td>{entry.floorArea}</td>
                                                                        <td>{entry.houseType}</td>
                                                                        <td>£{entry.mean.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                                        <td>£{entry.median.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                                        <td>{entry.modeRange}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <div className="d-flex gap-2 mt-2">
                                                        <Button 
                                                            variant="primary" 
                                                            onClick={downloadHistory}
                                                            disabled={predictionHistory.length === 0 || !status}
                                                        >
                                                            Download CSV
                                                        </Button>
                                                        <Button 
                                                            variant="secondary" 
                                                            onClick={copyToClipboard}
                                                            disabled={predictionHistory.length === 0 || !status}
                                                        >
                                                            Copy to Clipboard
                                                        </Button>
                                                    </div>
                                                </div>
                                    </Col>
                                </Row>
                            </>
                        }/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Predictor;