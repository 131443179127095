// src/MemberApp.js
import React from 'react';

import { Authenticator } from '@aws-amplify/ui-react';
import MemberPage from './pages/AccountPage';

import Predictor from './pages/Predictor/Predictor';
import SEOComponent from './components/SEOComponent';

export function PredictorApp() {
    return (
        <>
        <SEOComponent title="Predictor" description="Machine Learning Property Price Predictor"
        url="https://otta.property/machine-learning-property-price-predictor" image="https://otta.property/trends2.webp" />
        <Authenticator.Provider>
            <Predictor />
        </Authenticator.Provider>
    </>
    );
}






export function MemberApp() {
    return (
        <>     

        <SEOComponent title="Members" description="Members Page"
        url="https://otta.property/members" image="https://otta.property/trends2.webp" />

        <Authenticator.Provider>

    <MemberPage />

    </Authenticator.Provider>


    </>

    );
    }
