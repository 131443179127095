import ChartJSComponent from '../../components/ChartJSComponent';
import IndexChartJSComponent from '../../components/IndexChartJSComponent';

export const fetchChartData = async (dataFiles) => {
    const fetchPromises = dataFiles.map(async (file) => {
      try {
        const response = await fetch(file);
        const data = await response.json();
        return <ChartJSComponent key={file} data={data.plot_data} metadata={data.metadata} />;
      } catch (error) {
        // console.error("Error fetching file:", file, error);
        return null;
      }
    });
    return Promise.all(fetchPromises);
  };
  
  export const fetchIndexChartData = async (dataFiles) => {
    const fetchPromises = dataFiles.map(async (file) => {
      try {
        const response = await fetch(file);
        const data = await response.json();
        return <IndexChartJSComponent key={file} data={data.plot_data} metadata={data.metadata} />;
      } catch (error) {
        // console.error("Error fetching file:", file, error);
        return null;
      }
    });
    return Promise.all(fetchPromises);
  };