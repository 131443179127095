import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Posts from '../blog/BlogPosts';

const BlogPost = () => {
  const { postId } = useParams();
  const postEntry = Posts.find(post => post.link === postId);

  if (!postEntry) {
    return <Container><p>Post not found.</p></Container>;
  }

  const PostComponent = postEntry.Component;
  return (
      <PostComponent />
  );
}

export default BlogPost;
