import React from "react";
import { Container } from "react-bootstrap";
import SEOComponent from "../components/SEOComponent";
import SiteCard from "../components/SiteCard";

const Privacy = () => {

  const privacyPolicy = {
    websiteURL: "https://www.otta.property",
    email: "via our feedback form on the website",
    dataCollected: [
      "Anonymous usage data: browser type, pages visited, date and time of visit, and other statistics",
      "Personal Identification Information: email address",
      "Otta.property does not store or process any payment information. All payments are processed by a third party payment provider"
    ],
    purposesOfCollection: [
      "To improve our website and services",
      "To communicate with you, including responding to your inquiries",
      "To send promotional materials, newsletters, and other information with your consent",
      "To comply with legal obligations and protect our legal rights",
      "To use data analytics to improve our website."
    ],
    legalBasis: "We collect your data on the basis of consent and legitimate interest. We require your consent to process your personal data, and we have a legitimate interest in using your data to provide a service to you.",
    dataRetention: "We will retain your personal data only for as long as necessary for the purposes set out in this privacy policy, or as required by law. You also have the right to request that we erase your personal data.",
    dataSharing: "We will only share your personal information with third-party service providers for purposes such as payment processing. These third parties are contractually obligated to protect your data.",
    internationalTransfers: "Your information, including personal data, may be transferred to and maintained on servers located outside of your country. If we transfer your personal data out of the UK, we ensure a similar degree of protection by implementing safeguards such as standard contractual clauses or binding corporate rules.",
    userRights: [
      "Right to access: You have the right to request copies of your personal data.",
      "Right to rectification: You have the right to request that we correct any information you believe is inaccurate.",
      "Right to erasure: You have the right to request that we erase your personal data, under certain conditions.",
      "Right to restrict processing: You have the right to request that we restrict the processing of your personal data, under certain conditions.",
      "Right to data portability: You have the right to request that we transfer the data that we have collected to another organisation, or directly to you, under certain conditions.",
      "Right to object: You have the right to object to our processing of your personal data, under certain conditions.",
      "Right to withdraw consent: You have the right to withdraw your consent at any time where we relied on your consent to process your personal data.",
      "Right to lodge a complaint: If you have any concerns about our use of your personal data, you have the right to lodge a complaint with the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues."
    ],
    // 
    cookies: {
      useCookies: true,
      typesOfCookies: [
        "Essential Cookies: These cookies are necessary for the website to function properly.",
        "Analytical/Performance Cookies: These cookies allow us to analyse website usage and improve performance.",
        "Functionality Cookies: These cookies enable the website to provide enhanced functionality and personalisation.",
        "Targeting/Advertising Cookies: These cookies are used to deliver adverts more relevant to you and your interests."
      ],
      cookiePolicy: ["We use cookies to manage your login session, providing website functionality and security.",
      "We use cookies to anonymously track website usage, allowing us to understand how users interact with our website."],
      cookieSettings: "You can opt out of cookies by changing your browser settings. Please note that disabling cookies may affect the functionality of this website."
    },
    securityMeasures: "We implement appropriate technical and organisational measures to ensure a level of security appropriate to the risk",
    updatesToPolicy: "We may update our privacy policy from time to time. Any changes will be reflected on this page, and where appropriate, notified to you by email. Your continued use of the website after any changes indicates your acceptance of the new privacy policy.",
    contactInfo: {
      position: "Data Protection Officer",
      name: "Inorite Ltd",
      address: "20 Wenlock Road, London, N1 7GU",
    }
  };
  

  return (
    <>
      <SEOComponent
        title={'Privacy Policy'}
        description={'Privacy policy for otta.property website'}
        url={'https://otta.property/privacy'}
        image={'https://otta.property/trends2.webp'}
      />   
    <div>
    <Container style={{ width: "80%", marginBottom: "2rem", fontSize: ".8rem" }}>
    
    <SiteCard header="Privacy Policy" title="Privacy Policy for otta.property" content=
    { <>
      <h2>Privacy Policy</h2>
      <p>
        This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from {privacyPolicy.websiteURL} (the “Site”).
      </p>
      <h3>Data We Collect</h3>
      <p>
        We collect several types of information for various purposes to provide and improve our Service to you. This is kept to the absolute minimum and all data is anonymised where possible.
      </p>
      <h4>Types of Data Collected</h4>
      <ul>
        {privacyPolicy.dataCollected.map((data, index) => (
          <li key={index}>{data}</li>
        ))}
      </ul>
      <h4>Purposes of Collection</h4>
      <ul>
        {privacyPolicy.purposesOfCollection.map((purpose, index) => (
          <li key={index}>{purpose}</li>
        ))}
      </ul>
      <h4>Legal Basis for Processing</h4>
      <p>{privacyPolicy.legalBasis}</p>
      <h4>Data Retention</h4>
      <p>{privacyPolicy.dataRetention}</p>
      <h4>Data Sharing</h4>
      <p>{privacyPolicy.dataSharing}</p>
      <h4>International Transfers</h4>
      <p>{privacyPolicy.internationalTransfers}</p>
      <h4>User Rights</h4>
      <ul>
        {privacyPolicy.userRights.map((right, index) => (
          <li key={index}>{right}</li>
        ))}

      </ul>
      <h3>Cookies</h3>
      <p>
        We use cookies to provide the best experience on our website.
      </p>
      <h4>Types of Cookies</h4>
      <ul>
        {privacyPolicy.cookies.typesOfCookies.map((cookie, index) => (
          <li key={index}>{cookie}</li>
        ))}
      </ul>
      <p>{privacyPolicy.cookies.cookiePolicy}</p>
      <p>{privacyPolicy.cookies.cookieSettings}</p>
      <h3>Security Measures</h3>
      <p>{privacyPolicy.securityMeasures}</p>
      <h3>Updates to Policy</h3>
      <p>{privacyPolicy.updatesToPolicy}</p>
      <h3>Contact Information</h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us at:
      </p>
      <p>
        {privacyPolicy.contactInfo.position}<br />
        {privacyPolicy.contactInfo.name}<br />
        {privacyPolicy.contactInfo.address}<br />
        {privacyPolicy.contactInfo.email}<br />
        {privacyPolicy.contactInfo.phone}
      </p>
      </>
    } />



    </Container>
    </div>
    </>
    
  );
};

export default Privacy;
