// Code for the About page
import React from 'react';
import { Container } from 'react-bootstrap';

import SiteCard from '../components/SiteCard';
import SEOComponent from '../components/SEOComponent';
import {AdComponent} from '../components/AdComponent';

const AboutContent = () => {
  return (
    <Container>
    <div>
    <p>
          This website started as a little project github.io page to help me find a house (which is ongoing!) and to learn how to build software products.
          The userbase of the website is growing so it makes sense to build on the feedback I've received and make the website better. This website has been built
          by one person (@twallin_james - me), but in some ways that's a good thing because it means that I move quickly!
        </p>
<p>
          Property indices are not the most intuitive things to understand, or particularly fun to use.
          They're also not particularly granular or easy to dissect and really understand
          the detail. This often leads to a lack of trust in the companies who provide the insights.
        </p>

        <h5>The mission of this website is to:</h5>
        <ul>
          <li>Provide a transparent and easy to use tool for visualising property data</li>
          <li>Provide a tool that allows you to see the property market at a granular level</li>
          <li>Build on user feedback to make the website better</li>
          <li>Potentially <b>become the leading source of property insights in England and Wales</b></li>
        </ul>

        <h3>What data is being used</h3>
        <hr />
        <p>
          For this website, I'm using the <a href="https://www.gov.uk/government/statistical-data-sets/price-paid-data-downloads">Land Registry's Price Paid Data</a>.
          This data is updated monthly and contains the price paid for each property sold in England and Wales.
          </p>
        <p>
          This website also uses EPC data from the <a href="https://epc.opendatacommunities.org/">Open Data Communities</a> website.
          What's great about this data is that it contains the total floor area of the property, which means that for houses which have
          an EPC rating, we can calculate the price per square metre of the property.
        </p>

        <h3>So what's useful about this website?</h3>
        <hr />
        <p>
          In short, if you're looking to understand the property market in England and Wales, this website is for you.
        </p>
        <p>
          As I mentioned above, it's hard to get location data from the Land Registry in order to make a good map. It's also hard to
          get the price per square metre of a property.
          It's also hard to plot the data without your browser running out of memory. Those are the problems this website solves.
        </p>
      
        <p>
          Now, good products are driven by user needs. So, if you have any suggestions, please let me know.
          I'm always looking to improve the website and make it better for you.
        </p>
        <p>
          If you have any suggestions, please contact me on twitter: <a href="https://twitter.com/twallin_james">@twallin_james</a>.
          As I mentioned, I want to build on this website and make it as useful as possible. Property data should be transparent,
          easy to access and easy to understand.
        </p>

    </div>
    </Container>
  );
}

  

const About = () => {
  // example page



  return (
    <>
      <SEOComponent
        title={'About Otta.property'}
        description={'About the Otta.property website'}
        url={'https://otta.property/about'}
        image={'https://otta.property/trends2.webp'}
      />

      <AdComponent />

        <Container>
          <SiteCard header={`WHAT'S THE POINT OF THIS WEBSITE?`} title={'About Otta.property'} content={<AboutContent />} />
        </Container>
        
    </>
  );
}

export default About;
