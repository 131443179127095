import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row } from 'react-bootstrap';
import PropertyTypeSelect from './PropertyTypeSelect'; 
import {fetchIndexChartData} from './fetchChartData';
// accordion
import Accordion from 'react-bootstrap/Accordion';


const IndexDataTab = ({ selectedCounty, indexPropertyType, onPropertyTypeChange, onIndexLocationValuesChange }) => {
  const [charts1, setCharts1] = useState(null);
  const [indexLocationValues, setIndexLocationValues] = useState({});
  const [indexModelCommentaryValues, setIndexModelCommentaryValues] = useState({});
  const [postCodeLongNames, setPostCodeLongNames] = useState({});
  const [longName, setLongName] = useState('');

  // Handle changes in property type with a callback to the parent
  const handlePropertyTypeChange = useCallback((newType) => {
      if (onPropertyTypeChange) {
          onPropertyTypeChange(newType);  // Communicate changes to the parent component
      }
  }, [onPropertyTypeChange]);

  // Fetch chart data based on the selected county and property type
  useEffect(() => {
    const basePath = window.location.hostname === 'localhost' ? 'data' : 'ml_index';
    const path = `${basePath}/${selectedCounty}/${indexPropertyType}.json`;
    fetchIndexChartData([path])
      .then(setCharts1)
      .catch(error => console.error('Error fetching chart data:', error));
  }, [selectedCounty, indexPropertyType]);

  // Fetch and manage postcode long names
  useEffect(() => {
    const basePath = window.location.hostname === 'localhost' ? 'data' : 'misc';
    fetch(`${basePath}/uk_postcode_longnames.json`)
      .then(response => response.ok ? response.json() : Promise.reject('Network response was not ok'))
      .then(data => setPostCodeLongNames(data))
      .catch(error => console.error('Fetch error:', error));
  }, []);

  // Update long name based on selected county
  useEffect(() => {
    if (selectedCounty && postCodeLongNames[selectedCounty]) {
      setLongName(postCodeLongNames[selectedCounty]);
    }
  }, [selectedCounty, postCodeLongNames]);

  // Fetch and update location values, and communicate changes to the parent
  useEffect(() => {
    const basePath = window.location.hostname === 'localhost' ? 'data' : 'ml_index';
    fetch(`${basePath}/region${indexPropertyType}.json`)
      .then(response => response.ok ? response.json() : Promise.reject('Network response was not ok'))
      .then(data => {
        setIndexLocationValues(data);
        if (onIndexLocationValuesChange) {
          onIndexLocationValuesChange(data);
        }
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setIndexLocationValues({});
      });
  }, [indexPropertyType, onIndexLocationValuesChange]);

  useEffect(() => {
    const basePath = window.location.hostname === 'localhost' ? 'data' : 'ml_index';
      fetch(`${basePath}/model_commentary_${indexPropertyType}.json`)
      .then(response => response.ok ? response.json() : Promise.reject('Network response was not ok'))
      .then(data => {
        setIndexModelCommentaryValues(data);
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setIndexModelCommentaryValues({});
      }
      );
  }, [indexPropertyType]);


  const renderTitle = () => {
    return <h4>{longName}, {indexPropertyType === 'All' ? 'All property types' : indexPropertyType}</h4>;
  };

  const renderCommentary = () => {
    const data = indexLocationValues[selectedCounty];
    if (data) {
      const { max_value, twelve_months_ago, max_date, last_date, last_value, percent_change, last_and_max_percent_change } = data;
      return (
        <div>
          <p style={{ textAlign: 'left', fontSize: '0.8em' }}>
            Most recent prices are £{Math.round(last_value / 1000)}k in {last_date}. <br />
            Prices 12 months ago were £{Math.round(twelve_months_ago / 1000)}k. This is a {percent_change.toFixed(1)}% change.<br />
            Prices peaked at £{Math.round(max_value / 1000)}k in {max_date}. The change from peak to now is {last_and_max_percent_change.toFixed(1)}%.
          </p>
        </div>
      );
    }
    return null;
  };

  const renderSimpleModelCommentary = () => {
    const modelData = indexModelCommentaryValues[selectedCounty];
    if (!modelData) return null;
    
    const assessReliability = (value) => {
      if (value >= 0.7) return "highly reliable";
      if (value >= 0.6) return "reliable";
      if (value >= 0.5) return "moderately reliable";
      return "the model is probably very unreliable";
    };
  
    const reliability = assessReliability(modelData.average_value);
    
    return (
      <div>    <p style={{textAlign: 'left', fontSize: '0.8em' }} >
          The chart below can be considered {reliability} overall.
          <strong>
            {modelData.average_value > 0.6 ? " Even so, " : " With this in mind, "}
            please interpret with appropriate caution.
          </strong> More detail below the chart.
        </p>
      </div>
    );
  };
  


  const renderModelCommentary = () => {
    const modelData = indexModelCommentaryValues[selectedCounty];
  
    if (!modelData) return null;
  
    const assessPerformance = (value) => {
      if (value >= 0.8) return "excellent";
      if (value >= 0.7) return "very good";
      if (value >= 0.6) return "good";
      if (value >= 0.5) return "moderate";
      if (value >= 0.4) return "adequate";
      return "limited";
    };
  
    const formatValue = (value) => value.toFixed(3);
  
    const averageRSquared = formatValue(modelData.average_value);
    const recentRSquared = formatValue(modelData.years_average);
    const latestRSquared = formatValue(modelData.last_value);
    const peakRSquared = formatValue(modelData.max_value);
  
    return (
      
      <div className="model-commentary" >

      <p style={{textAlign: 'left', fontSize: '0.8em'}}>
        When building our models, we reserve a portion of data as 'test data' that remains unseen during training. 
        This test set helps evaluate the model's performance on new information. The test set has some known house prices, 
        so we use the input variables in the test set to predict house prices and compare them to the actual prices.
      </p>
      <p style={{textAlign: 'left', fontSize: '0.8em' }}>
      R squared, ranging from 0 to 1, measures how well our model explains house price variations using inputs
       like location and property type. We compare our model's predictions against actual prices in the test data.
      A value nearer 1 indicates greater confidence in the model's accuracy and our grasp of price-influencing factors.
       Conversely, a value closer to 0 suggests significant pricing aspects remain unaccounted for.
       This could be due to sparse training data in certain locations or property types (more common in recent months owing to reporting delays)
       or the model not fully capturing local market complexities.
        
        Below, we examine how well our model predicts prices for {longName}: {indexPropertyType}, using key metrics to assess its accuracy:
      </p>
        <ul style={{textAlign: 'left', fontSize: '0.8em' }}>
          <li>
            <strong>Long-term Performance:</strong> The average R-squared value is {averageRSquared}, which is considered {assessPerformance(modelData.average_value)}. This indicates the proportion of variance in the data explained by the model over the entire period.
          </li>
          <li>
            <strong>Recent Trends:</strong> Over the last 12 months, the average R-squared is {recentRSquared}, categorised as {assessPerformance(modelData.years_average)}. This is {modelData.years_average > modelData.average_value ? "higher" : "lower"} than the overall average, indicating {modelData.years_average > modelData.average_value ? "an increase" : "a decrease"} in model performance in the recent period.
          </li>
          <li>
            <strong>Latest Performance:</strong> The most recent R-squared value is {latestRSquared}, which is {assessPerformance(modelData.last_value)}. This is {modelData.last_value > modelData.years_average ? "above" : "below"} the recent 12-month average, showing {modelData.last_value > modelData.years_average ? "an improvement" : "a decline"} in the most recent period.
          </li>
          <li>
            <strong>Peak Performance:</strong> The model's highest R-squared value was {peakRSquared}, recorded in {modelData.max_date}. This {assessPerformance(modelData.max_value)} value represents the model's best performance to date.
          </li>
        </ul>
        <p style={{textAlign: 'left', fontSize: '0.8em' }}>
          In summary, the model for {longName}: {indexPropertyType} demonstrates {assessPerformance(modelData.average_value)} performance overall. The recent 12-month period shows {modelData.years_average > modelData.average_value ? "higher" : "lower"} R-squared values compared to the long-term average, and the most recent data point {modelData.last_value > modelData.years_average ? "exceeds" : "falls below"} the recent average.
        </p>
      </div>
    );
  };

  return (
    <Container className='chart-container' style={{marginTop: '1rem'}}> 
      {renderTitle()}
      {renderCommentary()} 
      <hr />
      {renderSimpleModelCommentary()}
    
      <Row>
        <PropertyTypeSelect
          propertyTypes={['All', 'Flat', 'Terraced', 'Semi-detached', 'Detached']}
          onSelect={handlePropertyTypeChange}
        />
        <Container style={{display: 'flex'}}>
          {charts1 || <div><b>Click on the map and buttons to load a chart</b></div>}
        </Container>
        
      </Row>
      <Row style={{marginTop: '1rem'}}>
        {/* accordion */}
        <Accordion >
          <Accordion.Item eventKey="0">
            <Accordion.Header>Model Performance Analysis</Accordion.Header>
            <Accordion.Body>
              {renderModelCommentary()}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>

    </Container>
  );
};

export default IndexDataTab;
