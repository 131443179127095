import React from 'react';
import Table from 'react-bootstrap/Table';
import './StripeStatusTable.css'; // Import custom CSS

function StripeStatusTable({ stripeStatus }) {
  const stringLookup = {
    'current_period_end': 'Renewal/End Date',
    'current_period_start': 'Start Date',
    'cancel_at_period_end': 'Cancel At Period End',
    'canceled_at': 'Canceled At',
    'stripe_sub_status': 'Subscription Status',
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      year: 'numeric', month: 'short', day: '2-digit'
    }).split(' ').reverse().join('-'); // Simplified date formatting
  };

  const getDisplayValue = (key, value) => {
    if (['current_period_end', 'current_period_start', 'canceled_at'].includes(key)) {
      return formatDate(value);
    }
    return key === 'cancel_at_period_end' ? (value ? 'Yes' : 'No') :
           key === 'stripe_sub_status' ? value.charAt(0).toUpperCase() + value.slice(1) :
           value.toString();
  };

  // Custom sorting to place 'current_period_start' immediately after the first row
  const orderedStatus = {};
  Object.keys(stripeStatus).forEach(key => {
    if (key === 'current_period_start') {
      orderedStatus[key] = stripeStatus[key];  // Ensures 'Start Date' comes first
    }
  });

  // Add the rest of the keys except 'current_period_start'
  Object.keys(stripeStatus).forEach(key => {
    if (key !== 'current_period_start') {
      orderedStatus[key] = stripeStatus[key];
    }
  });

  // if the cancel_at_period_end is false, remove the canceled_at key and also the cancel_at_period_end key
  if (stripeStatus.cancel_at_period_end === false) {
    delete orderedStatus.canceled_at;
    delete orderedStatus.cancel_at_period_end;
  }
  
  return (
    <div className="stripe-status-table">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Title</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(orderedStatus).map(([key, value]) => (
            <tr key={key}>
              <td>{stringLookup[key] || key}</td>
              <td>{getDisplayValue(key, value)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default StripeStatusTable;
