import React from 'react';

const PropertyTypeSelect = ({ propertyTypes, onSelect }) => {
  return (
    <div style={{ gap: '1em', display: 'flex', justifyContent: 'center', fontSize: '0.8em' }}>
      {propertyTypes.map((propertyType, index) => (
        <label key={index}>
          <input type="radio" name="propertyType" value={propertyType} onChange={e => onSelect(e.target.value)} />
          {propertyType}
        </label>
      ))}
    </div>
  );
};

export default PropertyTypeSelect;
