import React, { useState, useRef, useEffect } from 'react';
import './priceRangeSlider.css'; // Assume your CSS is defined here


const PriceRangeSlider = ({ min_label,max_label, min, max, value1, value2, onChange1, onChange2}) => {
  const [leftValue, setLeftValue] = useState(value1);
  const [rightValue, setRightValue] = useState(value2);
  const rangeRef = useRef(null); // Ref for the container



  useEffect(() => {
    const rangeContainer = rangeRef.current;

  
    const handleInteraction = (e) => {
      if (!rangeContainer) return;
    
      // Determine if the event is a touch event
      const isTouchEvent = e.touches && e.touches.length > 0;
      const clientX = isTouchEvent ? e.touches[0].clientX : e.clientX;
    
      const rangeRect = rangeContainer.getBoundingClientRect();
      const position = (clientX - rangeRect.left) / rangeRect.width * 1000;
      
      // Calculate the closest slider to the touch position
      const leftDistance = Math.abs(Math.max(leftValue, 0) - position);
      const rightDistance = Math.abs(Math.max(rightValue, 1000) - position);

      function updateValueAndTrigger(setValue, onChange, newValue) {
        setValue(newValue);
        onChange(newValue);
      }

      if (leftDistance < rightDistance) {
      // Closest to left slider or position clearly belongs to the left side
      const newValue = Math.min(Math.max(position, 1), leftValue);
      updateValueAndTrigger(setLeftValue, onChange1, newValue);
        } else if (rightDistance < leftDistance) {
          // Closest to right slider or position clearly belongs to the right side
          const newValue = Math.min(Math.max(position, rightValue), 1000);
          updateValueAndTrigger(setRightValue, onChange2, newValue);
        } else {
     // For positions not clearly closer to either slider, adjust based on thresholds
    if (position < 500) {
    // If position is in the first half, adjust leftValue
      const newValue = Math.min(Math.max(position, 1), leftValue);
      updateValueAndTrigger(setLeftValue, onChange1, newValue);
    } else {
      // If position is in the second half, adjust rightValue
      const newValue = Math.min(Math.max(position, rightValue), 1000);
      updateValueAndTrigger(setRightValue, onChange2, newValue);
    }
  }
    }; 



      
    rangeContainer.addEventListener('mousedown', handleInteraction);
    rangeContainer.addEventListener('touchstart', handleInteraction);
    
    return () => {
      rangeContainer.removeEventListener('mousedown', handleInteraction);
      rangeContainer.removeEventListener('touchstart', handleInteraction);
    };
  }, [leftValue, rightValue, min, max, onChange1, onChange2]);


    // Calculate the gradient style based on slider values
  const calculateTrackStyle = () => {
      const percentageLeft = (leftValue / 1000) * 100;
      const percentageRight = (rightValue / 1000) * 100;
      return {
        background: `linear-gradient(to right, #ddd 0%, #ddd ${percentageLeft}%, #007bff ${percentageLeft}%, #007bff ${percentageRight}%, #ddd ${percentageRight}%, #ddd 100%)`,
      };
    };
  
  const handleLeftChange = (newValue) => {
    // clip the value to be between 0 and 100
    const clippedValue = Math.min(Math.max(newValue, 0), 930);

    // Prevent the left slider from going past the right slider
    if (clippedValue > rightValue) {
      setLeftValue(rightValue-150);
      onChange1(rightValue-150);
    } else {
      setLeftValue(clippedValue);
      onChange1(clippedValue);
    }

  };

  const handleRightChange = (newValue) => {

    // clip the value to be between 0 and 100
    const clippedValue = Math.min(Math.max(newValue, 70), 1000);
    // Prevent the right slider from going below the left slider
    if (clippedValue < leftValue) {
      setRightValue(leftValue+150);
      onChange2(leftValue+150);

    } else {
      setRightValue(clippedValue);
      onChange2(clippedValue);
    }

    
  };

  
  

return (
        <div className="price-range-container">
<div className="price-range-labels row flex-nowrap justify-content-between">
    <div className="col text-left">
        <span>{min_label}</span>
    </div>
    <div className="col text-center" style={{fontSize: "0.6rem"}}>
      <span>Price Range</span>
    </div>
    <div className="col text-right">
        <span>{max_label}</span>
    </div>
</div>
    <div className="multi-range" ref={rangeRef}>
      <input
        type="range"
        id="leftSlider"
        min={min}
        max={max}
        value={leftValue}
        onChange={(e) => handleLeftChange(e.target.value)}
      />
      <input
        type="range"
        id="rightSlider"
        min={min}
        max={max}
        value={rightValue}
        onChange={(e) => handleRightChange(e.target.value)}
      />
      <div className="track" style={calculateTrackStyle()} />
    </div>
  </div>
  );
};

export default PriceRangeSlider;