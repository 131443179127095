import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PredictionChart = ({ data }) => {
  const binData = useMemo(() => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return null;
    }
  
    const binSize = 25000;
    const values = data.map(item => item.value);
    const minValue = Math.max(0, Math.floor((Math.min(...values) - 150000) / binSize) * binSize);
    const maxValue = Math.ceil((Math.max(...values) + 150000) / binSize) * binSize;
  
    const binCount = (maxValue - minValue) / binSize;
    const bins = Array(binCount).fill(0);
  
    values.forEach(value => {
      const binIndex = Math.floor((value - minValue) / binSize);
      bins[binIndex]++;
    });
  
    const formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    
  
    return {
      binLabels: bins.map((_, index) => 
        `${formatter.format(minValue + index * binSize)} - ${formatter.format(minValue + (index + 1) * binSize)}`
      ),
      binCounts: bins
    };
  }, [data]);

  if (!binData) {
    return <div>No data available</div>;
  }

  const chartData = {
    labels: binData.binLabels,
    datasets: [
      {
        label: 'Estimate Count',
        data: binData.binCounts,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }
    ]
  };

  const maxCount = Math.max(...binData.binCounts);


  const options = {
    responsive: true,
    aspectRatio: 1.1,
    layout: {
      padding: {
        top: 10,
        right: -6,
        bottom: 0,
        left: -6,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Estimate Distribution (£25,000 bins)'
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Value Ranges'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Count'
        },
        beginAtZero: true,
        max: maxCount + 1, // Set the max to be one greater than the highest count
        ticks: {
          stepSize: 1, // Ensure whole number ticks
          precision: 0 // Ensure no decimal places
        }
      }
    }
  };

  return <Bar data={chartData} options={options} />;
};

export default PredictionChart;